import {utils} from '@vx/framework';
import Tag from '../Tag';

export default class extends Tag {
  template(params) {
    return `
            <div class="vo-form-group">
                <label>${params.label}
                    ${params.platinTooltipStr}
                </label>
                <div id=${params.idNew} vx-wrapper-for="${params.name}">
                    ${params.optionsStr}
                </div>
                <span vx-error-message-for="${params.name}" class="vo-error-wrapper" style="color: #c8231c"></span>
            </div>
        `;
  }

  prepareParams(o, params) {
    const destProperties = params.name.split(':', 2);
    const defaultNameFormat = destProperties.length === 1; // czyli po prostu nazwa, co oznacza, ze id jest w nazwa.id a label w nazwa.label
    params.destId = defaultNameFormat
      ? `${destProperties[0]}.id`
      : destProperties[0];
    params.destLabel = defaultNameFormat
      ? `${destProperties[0]}.label`
      : destProperties[1];

    params.optionsStr = this.generateOptions(o, params);
  }

  generateOptions(o, params) {
    let string = '';
    const options = o.getDictionary(params.dict);
    const value = utils.getPropertyOrNull(o.data, params.name);
    const seli = options.length;
    let checkedString = '';

    const itemWidth = params.itemWidth
      ? `style="width: ${params.itemWidth}"`
      : '';

    for (let ii = 0; ii < seli; ii++) {
      if (value && options[ii].id === value.id) checkedString = 'checked';
      else checkedString = '';
      // <div class="vo-radio-input"></div>
      string += `
        <div class="vo-radio" ${itemWidth}>
            <input
                type="radio"
                name="${params.destId}"
                ${checkedString}
                value="${options[ii].id}"
                vx-label-name="${params.destLabel}"
                vx-label-value="${options[ii].label}"
                ${params.readonlyb}
                 />
            <label>${options[ii].label}</label>
        </div>
            `;
    }
    return string;
  }
}
