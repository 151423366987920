import Tag from '../Tag';

export default class extends Tag {
  template(params) {
    return `
      <td class="${params.extraClasses}">--nestedContent--</td>
    `;
  }

  prepareParams(o, params) {
    params.extraClasses = params.extraClasses || '';
  }
}
