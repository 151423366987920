import vx from "@vx/framework";

import PlatinText from "./form/vx-text";
import PlatinButton from "./form/vx-button";
import PlatinDate from "./form/vx-date";
import PlatinSelect from "./form/vx-select";
import PlatinRadio from "./form/vx-radio";
import PlatinCheckbox from "./form/vx-checkbox";

import PlatinSimpleHeader from "./simpletable/header";
import PlatinSimpleRow from "./simpletable/row";
import PlatinSimpleSorting from "./simpletable/sorting";
import PlatinSimpleSortitem from "./simpletable/sortitem";
import PlatinSimpleTable from "./simpletable/table";
import PlatinSimpleTd from "./simpletable/td";
import PlatinSimpleTh from "./simpletable/th";

import PlatinLightExpandBox from "./expand-box/expand-box";

import PlatinLightSortableTable from "./sortable-table/table";
import PlatinLightSortableTableBody from "./sortable-table/tbody";
import PlatinLightSortableTableHeader from "./sortable-table/thead";
import PlatinLightSortableTableTh from "./sortable-table/th";
import PlatinLightSortableTableTr from "./sortable-table/tr";
import PlatinLightSortableTableTd from "./sortable-table/td";

// eslint-disable-next-line
export const tags = {};

tags.text = new PlatinText();
tags.button = new PlatinButton();
tags.date = new PlatinDate();
tags.select = new PlatinSelect();
tags.radio = new PlatinRadio();
tags.checkbox = new PlatinCheckbox();

tags["expand-box"] = new PlatinLightExpandBox();

tags.simpletable = new PlatinSimpleTable();
tags["simpletable-header"] = new PlatinSimpleHeader();
tags["simpletable-row"] = new PlatinSimpleRow();
tags["simpletable-sorting"] = new PlatinSimpleSorting();
tags["simpletable-sortitem"] = new PlatinSimpleSortitem();
tags["simpletable-td"] = new PlatinSimpleTd(); // <vx-simpletable-td>
tags["simpletable-th"] = new PlatinSimpleTh();

tags["sortable-table"] = new PlatinLightSortableTable();
tags["sortable-table-body"] = new PlatinLightSortableTableBody();
tags["sortable-table-header"] = new PlatinLightSortableTableHeader();
tags["sortable-table-td"] = new PlatinLightSortableTableTd();
tags["sortable-table-tr"] = new PlatinLightSortableTableTr();
tags["sortable-table-th"] = new PlatinLightSortableTableTh();

tags.markers = tags.markers || {};

tags.init = function(vxParams) {
  // zmienna vx uzywana przez tagi jest inna niz ta, uzywana w aplikacji korzystajacej z tagow.
  // stad koniecznosc parametryzacji wiksow wewnatrztagowych
  vx.setup(vxParams);
};

export default tags;
