import vx from "@vx/framework";
import host from "./index";
import "../css/main.css";
import hashChangeHandler from "./platin/hashChangeHandler";
import login from "./platin/login";
import SharedUIController from "./components/SharedUIController";

export default async function () {
  // biblioteki globalne widziane w modułach biznesowych jako "externals"
  window.nvx = vx;
  window.host = host;

  //inicjalizacja wiksów
  vx.init(host.vxParams);
  const isLogged = login();
  //w czasie logowania zanim przeszedl na nową stronę ładował nagłowek
  //w którym były usługi, które kończyły się 401
  //poniższy warunek powoduje przerwanie ładownia strony bo i tak zaraz załaduje się CAS
  if (!isLogged) return;
  const normalMode = (window.location.href.indexOf("iframe.html") == -1);
  //jesli NIE jestesmy w trybie iframe (jest taki tryb na jednej ze stron FO)
  //ladujemy normalny naglowek
  if (normalMode) vx.load(SharedUIController, "shared-ui-area");
  window.addEventListener("hashchange", hashChangeHandler, false);
  if (!window.location.hash) window.location.hash = "#welcome/main";
  hashChangeHandler();
  //od razu sprawdzamy co jest w bieżącym hashu i ładujemy co trzeba
}
