import Template from './SecondaryNavigationTemplate.vtl';

export default function SecondaryNavigationController() {
  const self = this;
  self.template = Template;

  self.messages = {
    refreshSecondaryNav: menuData => {
      self.activeSubmenu = menuData;
      self.render();
    },
  };

  self.init = function init({menuData}) {
    self.activeSubmenu = menuData;
    self.render();
  };
}
