/* eslint-disable */
import {ajax} from '@vx/framework';

const meEp = 'light/employee';

// Kontekst Operatora.
// -------------------

/**
 * @return {Object|null}
 * @example
 * var result = {
 * 	employeeId: "Q0D1F61A",
 * 	realUnitNumber: "B132",
 * 	roles: [],
 * 	statisticalUnitNumber: "BH68",
 *  ip: '1.1.1.1',
 *  expires: 1628027999999,
 * }
 */
export const getSubject = function() {
  const result = sessionStorage.getItem('platinEncodedToken');
  return result ? JSON.parse(result) : result;
};

export async function getMe(force) {
  let me = JSON.parse(sessionStorage.getItem('user'));

  if (!me || force) {
    me = await ajax.get(meEp, null, false);
    sessionStorage.setItem('user', JSON.stringify(me));
  }

  return me;
}
