import template from './ConfirmTemplate.vtl';

export default function() {
  const self = this;
  self.template = template;

  self.onYes = function() {
    if (self.yesCallback) self.yesCallback();
    self.popup.close();
  };

  self.onNo = function() {
    if (self.noCallback) self.noCallback();
    self.popup.close();
  };

  self.init = function(params) {
    self.areaId = params.areaId;
    self.title = params.title;
    self.message = params.message;
    self.yesCallback = params.yes;
    self.yesLabel = params.yesLabel || 'Tak';
    self.noCallback = params.no;
    self.noLabel = params.noLabel || 'Nie';
    self.popup = params.popup;
    self.icon = params.icon;
    self.render();
    self.popup.adjustSize();
  };

  self.events = {
    'yesButton click': self.onYes,
    'noButton click': self.onNo,
  };
}
