export default {
    displayFormat: function (value) {
        if (value || value === 0) {
            var withoutLetters = value.toString();  //zamiana na string (w sytuacji, gdy na wejściu mamy liczbę a nie string).
            withoutLetters = withoutLetters.replace(/,/g, '.');  //zamiana przecinkow na kropki
            withoutLetters = withoutLetters.replace(/\.+/g, '\.');  //usuniecie nadmiaru kropek
            withoutLetters = withoutLetters.replace(/[^\d.-]/g, '');  //usuniecie liter (z wyjatiem kropki i minus)
            withoutLetters = withoutLetters.replace(/-+/g, '-');  //usuniecie nadmiaru minusów
            return vx.formatting.formatNumber(withoutLetters, 2, '.', ' ');
        }
        return '';
    },

    modelFormat(value) {
        return value.replace(/\s/g,'').replace(',','.');
    }
}