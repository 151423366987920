
  import { modelFormatters } from '@vx/framework';

  const template = function(o, tags){
    var _e= o.encode;
    

    let _s='<ul> '; 
    for (let i = 0; i < o.activeSubmenu.length; i++) { const item =
  o.activeSubmenu[i]; const activeClass = item.active ? 'active current' : '';
  var href = o.app.navigation.currentEnvironment + item.href;
  _s+=' <li class="'+_e( activeClass )+'"> <a href="'+_e( href )+'">'+_e( item.label )+'</a> </li> '; } _s+=' </ul> <div class="wrapper"></div> ';
    return _s;
  }; 
  template._FILEPATH = 'src/js/components/secondary-navigation/SecondaryNavigationTemplate.vtl';
  export default template;
  