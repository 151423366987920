import Template from './FooterTemplate.vtl';

export default function() {
  this.template = Template;
  this.canRemoveEvents = false;

  this.init = function() {
    this.render();
  };
}
