
  import { modelFormatters } from '@vx/framework';

  const template = function(o, tags){
    var _e= o.encode;
    

    let _s='<div class="vo-alert-popup"> <h2> '; if(o.icon) { _s+='<span class="'+_e( o.icon )+'"></span>'; } _s+=' '+_e( o.title )+' </h2> <div class="clr"></div> <div class="vo-popup-message"> '+( o.message ||'')+' </div> <div class="clr"></div> <div class="clr"></div> <div class="buttons vo-buttons-right"> '+( tags['button'].begin(o, {"id":"okButton","label": o.okLabel }) ||'')+''+( tags['button'].end() ||'')+' </div> </div>';
    return _s;
  }; 
  template._FILEPATH = 'src/js/platin/controllers/popup/AlertTemplate.vtl';
  export default template;
  