
  import { modelFormatters } from '@vx/framework';

  const template = function(o, tags){
    var _e= o.encode;
    

    let _s='<style> .logTrace {\n        color: #00000b;\n    }\n    .footer-wrapper a {\n        text-decoration: none;\n    } </style> <span class="icon-info-circle text-gray-dark" style="float: left; margin-right: 5px; font-size: 12px;"></span> Platin CRM &copy; 2008-'+_e( new Date().getFullYear() )+' Departament Rozwoju Aplikacji Sprzedażowych i Wsparcia <a class="logTrace" href="#admin/Log?level=TRACE">PKO</a> Bank Polski ';
    return _s;
  }; 
  template._FILEPATH = 'src/js/components/footer/FooterTemplate.vtl';
  export default template;
  