import Template from './ErrorTemplate.vtl';

export default function ErrorController() {
  const self = this;

  self.template = Template;

  function closeErrorModal() {
    window.location.hash = 'welcome/main';
    window.location.reload();
  }

  self.init = function init({ err }) {
    err.date = err.date || new Date();
    self.err = err;
    self.render();
  };

  self.events = {
    '#closeButtonId click': closeErrorModal,
  };
}
