import {utils} from '@vx/framework';
import Tag from '../Tag';

export default class extends Tag {
  template(params) {
    return `
      <tr id="${params.idNew}">--nestedContent--</tr>
    `;
  }

  prepareParams(o, params) {
    const checked = o.isCheckedAll ? `vx-checked="checked"` : '';
    params.checkboxStr = o.checkable
      ? `
            <span class="data-table-cell vo-data-table-checkbox-cell checkbox"><input type="checkbox" ${checked}/></span>
        `
      : '';

    params.idFromController = utils.getPropertyOrNull(o.tableParams, 'id');
  }
}
