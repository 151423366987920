import {utils} from '@vx/framework';
import Tag from '../Tag';

export default class extends Tag {
  template(params) {
    return `
            <div id="${
              params.idFromController
            }-sort-search-header" class="vo-table-sort-header">
                <div class="vo-sort-group-horizontal">
                    <label for="${
                      params.idFromController
                    }" class="vo-label-horizontal" style="cursor: auto;">${
      params.label
    }
                        ${params.platinTooltipStr}
                    </label>
                    <div class="vo-select-wrapper" vx-wrapper-for="sortBy">
                        <select name="sortBy" id="${
                          params.idFromController
                        }" class="vo-form-control" ${params.pkoFirefoxStyle}>
                                --nestedContent--
                        </select>
                    </div>
                </div>
                ${
                  !params.hideFromController
                    ? `
                    <span class="input-wrapper input-with-button input-large input-wrapper-quicksearch " style="width:220px; float : right; margin-bottom : 7px">
                            <input name="searchInput" class="input" type="text" >
                            <a vx-search="1" class="button button-gradient icon-search"></a>
                    </span>
                `
                    : ''
                }

            </div>
        `;
  }

  prepareParams(o, params) {
    const isPkoFF = navigator.buildID && navigator.buildID === '20131205075310';
    params.pkoFirefoxStyle = isPkoFF ? 'style="= padding-top: 2px;"' : '';

    params.idFromController = utils.getPropertyOrNull(o.tableParams, 'id');
    params.hideFromController = utils.getPropertyOrNull(
      o.tableParams,
      'hideSearch',
    );

    params.label = params.label || 'Sortuj po:';
  }
}
