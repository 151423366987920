import {utils} from '@vx/framework';
import Tag from '../Tag';

export default class extends Tag {
  template(params) {
    return `
      <div id="div_${params.idNew}" class="vx-input-div vx-select" ${params.styleWidth}>
        <div class="vx-input-div-inner">
          <label for="${params.name}">${params.label}</label>
          <select id="${params.idNew}" name="${params.name}">
            ${params.promptStr}
            ${params.optionsStr}
          </select>
        </div>
      </div>
    `;
  }

  prepareParams(o, params) {
    params.width = params.width || '100%';
    const isPkoFF = navigator.buildID && navigator.buildID === '20131205075310';
    params.pkoFirefoxStyle = isPkoFF ? 'style="= padding-top: 2px;"' : '';
    params.promptStr = params.prompt
      ? `<option value="">${params.prompt}</option>`
      : '';

    params.optionsStr = this.generateOptions(o, params);
  }

  generateOptions(o, params) {
    let string = '';
    const options = o.getDictionary(params.dict);
    const seli = options.length;
    const value = utils.getPropertyOrNull(o.data, params.name);
    let selectedString = '';

    for (let ii = 0; ii < seli; ii++) {
      if (value && options[ii].id === value.id) {
        selectedString = 'selected="true"';
      } else selectedString = '';
      string += `<option value="${options[ii].id}" ${selectedString}>${options[ii].label}</option>`;
    }
    return string;
  }
}
