import { formatedPhoneNumber, unformatedPhoneNumber } from './phoneFormatter'

export default {
    displayFormat(value){
        var numberLength = 11;
        var mask = "+__-___-___-___";
        return formatedPhoneNumber(value, mask, numberLength );
    },
    modelFormat(valueToFormat) {
        var value = unformatedPhoneNumber(valueToFormat);
        return value;
    }
}