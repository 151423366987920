import Tag from '../Tag';
import tagutils from '../vx-tags-utils';

export default class extends Tag {
  template(params) {
    return `
            <div class="vo-form-group vo-checkbox" ${params.styleWidth}>
                <input type="checkbox" name="${params.name}" id="${params.idNew}" ${params.checkedb} ${params.readonlyb}></input>
                <div class="vo-checkbox-input" vx-wrapper-for="${params.name}"></div>
                    <label>
                    ${params.label}
                    ${params.platinTooltipStr}
                    </label>
                    <span vx-error-message-for="${params.name}" style="color: #c8231c; margin-left: 0.5em" class="vo-error-wrapper"></span>
            </div>
        `;
  }

  prepareParams(o, params) {
    params.checkedb = tagutils.genAttribByName(o, params.name, 'checked');
  }
}
