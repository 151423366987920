import vx, { ajax } from "@vx/framework";
import Template from "./HeaderTemplate.vtl";
import NavigationController from "../navigation/NavigationController";

const salesUnitPath = "light/login/salesunit";

export default function HeaderController() {
  const self = this;
  self.template = Template;
  self.canRemoveEvents = false;

  async function getUserDetails() {
    const user = await self.app.to(self.app.context.currentEmployee.getMe());

    self.userData = {
      firstName: user.firstName,
      surname: user.surname,
      selectedUnit: user.salesUnits.find(
        el => el.statisticalUnitNumber === user.lastUnitNumber
      ),
      id: user.employeeId,
      availableUnits: user.salesUnits
    };
  }

  function rerender() {
    self.render();
    // debugger;
    setTimeout(() => {
      vx.load(NavigationController, {
        areaId: self.menuId,
        abortable: false
      });
    }, 0);
  }

  async function changeUserUnit(event) {
    const newUnitId = event.target.getAttribute("vx-unit-number");
    const newToken = (
      await self.app.to(
        ajax.post(salesUnitPath, null, {
          statisticalUnitNumber: newUnitId
        })
      )
    ).token;
    sessionStorage.removeItem("platinEncodedToken");
    sessionStorage.removeItem("user");
    sessionStorage.setItem("platinToken", newToken);

    window.location.reload();
  }

  self.init = async function init() {
    self.menuId = "menu";

    await getUserDetails();
    rerender();
  };

  self.events = {
    "#unitListId click": changeUserUnit
  };
}
