
  import { modelFormatters } from '@vx/framework';

  const template = function(o, tags){
    var _e= o.encode;
    

    let _s=''; var display = o.hidden ? 'none' : 'block'; _s+=' <div id="'+_e( o.containerId )+'" class="minipopupContainerShow" style="top: 0px; left: 0px; z-index: 1000; width: 154px; display: '+_e( display )+';"> <div class="vxDPContainer"> <div> <span class="vxDPHeader">'+_e( o.model.getMonthAndYear() )+'</span> <a class="vxDPClose" href="javascript:;">x</a> </div> <div class="vxDPYearHeader"> <a href="javascript:;" vx-dp-action="onPrevYear" class="vxDPYearHeaderNavi">«</a> <a href="javascript:;" vx-dp-action="onPrevMonth" class="vxDPYearHeaderNavi">‹</a> <a href="javascript:;" vx-dp-action="onToday" class="vxDPYearHeaderToday"> Dzisiaj </a> <a href="javascript:;" vx-dp-action="onNextMonth" class="vxDPYearHeaderNavi">›</a> <a href="javascript:;" vx-dp-action="onNextYear" class="vxDPYearHeaderNavi">»</a> </div> <div style="clear: both;"> <div class="vxDPDayHeader">Po</div> <div class="vxDPDayHeader">Wt</div> <div class="vxDPDayHeader">Śr</div> <div class="vxDPDayHeader">Cz</div> <div class="vxDPDayHeader">Pi</div> <div class="vxDPDayHeader">So</div> <div class="vxDPDayHeader">Ni</div> '; const days = o.model.getDays(); for (let i = 0; i < days.length; i++) {
      const day = days[i]; const weekClass = day.weekDay === 0 ? 'vxDPSunday' :
      day.weekDay === 6 ? 'vxDPSaturday' : ''; if (day.otherMonth) { _s+=' <div class="vxDPEmptyDay"></div> '; } else { _s+=' <div class="'+_e( day.isSelected ? 'vxDPSelectedDay': day.isToday ? 'vxDPCurrentDay': '' )+' '+_e( weekClass )+' vxDPDay" vx-dp-date="'+_e( day.date )+'" vx-dp-action="setDay"> '+_e( day.day )+' </div> '; } } _s+=' </div> </div> </div> ';
    return _s;
  }; 
  template._FILEPATH = 'src/js/vx-setup/tags/controllers/Datepicker/DatepickerTemplate.vtl';
  export default template;
  