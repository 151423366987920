import { formatedPhoneNumber, unformatedPhoneNumber } from './phoneFormatter'

export default {
    displayFormat(value){
        var numberLength = 9;
        var mask = "___-___-___";
        return formatedPhoneNumber(value, mask, numberLength);
    },

    modelFormat(valueToFormat) {
        var value = unformatedPhoneNumber(valueToFormat);
        return value;
    }
}