export default {

    displayFormat : function(value) {
        if (value || value === 0) {
            var prepared = Number(value.toString().replace(',','.').replace(/%/g, '')).toFixed(2);
            if(prepared !== 'NaN'){
                return prepared.replace('.',',');
            } else {
                return '0,00';
            };
        }
        return '';
    },

    modelFormat : function(valueToFormat) {
        return Number(valueToFormat.replace(/%/g,''));
    }
}