import {utils} from '@vx/framework';
import Tag from '../Tag';
import Controller from '../controllers/Datepicker/DatepickerController';

export default class extends Tag {
  template(params) {
    return `
      <div id="div_${params.idNew}" class="vx-input-div"  ${params.styleWidth}>
        <div class="vx-input-div-inner">
          <label for="${params.name}">${params.label}</label>
          <input type="text" vx-tag-component-id="${params.componentId}" class="vxDPInputDate" id="${params.idNew}" name="${params.name}" maxlength="10"  ${params.valueStrNew} />
          <div id="${params.idNew}-positioner" style="position: absolute"></div>
        </div>
      </div>
    `;
  }

  prepareParams(o, params) {
    params.minDate = params.minDate || '';
    params.maxDate = params.maxDate || '';

    params.componentId = utils.guid();
    this.controller = Controller;
    this.controllerParams = {
      areaId: `${params.idNew}-positioner`,
      inputId: params.idNew,
      minDate: params.minDate,
      maxDate: params.maxDate,
    };
  }
}
