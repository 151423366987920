/* eslint-disable */
import vx, { ajax, binding, modelValidators } from '@vx/framework';
import { LOCAL_API_PATH } from '../utils';

import { login } from './login';
import { errorPopup } from './index';

const extensions = {};
const MODULE_VERSIONS = {};

let messagesControllerPromise = null;

extensions.API_PATH = LOCAL_API_PATH;

extensions.beforeAjaxRequest = function (xhr) {
  xhr.setRequestHeader('X-AUTH-TOKEN', sessionStorage.platinToken);
};

extensions.getSessionId = function () {
  return encodeURI(`${sessionStorage.platinToken.split('.')[1]}-${sessionStorage.loginTime}`);
};

extensions.permissionVersionGenerator = function () {
  return encodeURI(`${sessionStorage.platinToken.split('.')[1]}-${sessionStorage.loginTime}`);
};

extensions.ajaxStart = function () {
  // console.log('AJAX- start');
};

extensions.ajaxStop = function () {
  // console.log('AJAX- stop');
};

extensions.logErrorOnServer = function () {
  // TODO
};

extensions.defaultAjaxErrorHandler = function (xhr) {
  let errorData = {
    type: 'error',
    status: xhr.status,
    url: xhr.responseURL,
    message: 'Wystąpił nieoczekiwany błąd serwera.',
    date: new Date(),
  };
  extensions.showErrorMessage(errorData);
};

extensions.showMessage = async function (message) {
  if (message.type === 'error') errorPopup({ error: message });
};

/**
 * Wyświetlenie komunikatu błędu
 * @example
 */
extensions.showErrorMessage = function (messageData) {
  extensions.showMessage(messageData);
};

extensions.showValidationMessages = function (areaId, messages) {
  const showValidationMessage = function (message) {
    const $area = document.getElementById(areaId);
    const $wrapperElement = $area.querySelector(`[vx-wrapper-for="${message.propertyName}"]`);
    if (!$wrapperElement) return;
    $wrapperElement.classList.add('error');
    const $validationSpan = $area.querySelector(`span[vx-error-message-for="${message.propertyName}"]`);
    if ($validationSpan) $validationSpan.innerHTML += `${message.message} `;
  };
  messages.forEach(showValidationMessage);
};

extensions.clearValidationMessages = function (areaId) {
  const $area = document.getElementById(areaId);
  const $validationSpans = $area.querySelectorAll('[vx-error-message-for]');
  let len = $validationSpans.length;
  while (len--) $validationSpans[len].innerHTML = '';
  const $wrappers = $area.querySelectorAll('[vx-wrapper-for]');
  let i = $wrappers.length;
  while (i--) $wrappers[i].classList.remove('error');
};

extensions.clearValidationMessage = function (areaId, propertyName) {
  const $area = document.getElementById(areaId);

  // propertyName can be for single or cross-validated inputs
  const splittedProperties = propertyName.split(',');
  splittedProperties.forEach(function (property) {
    const $wrapperElement = $area.querySelector(`[vx-wrapper-for="${property}"]`);
    if (!$wrapperElement) return;
    $wrapperElement.classList.remove('error');
    const $validationSpan = $area.querySelector(`span[vx-error-message-for="${property}"]`);
    if ($validationSpan) $validationSpan.innerHTML = '';
  });
};

extensions.beforeAjaxRequest =
  extensions.beforeAjaxRequest ||
  function (xhr) {
    // to rozszerzenie zostanie zdefiniowane w login.js
  };

extensions.bindAndValidate = async function (areaId, obj, validators) {
  if (!areaId) throw new Error('Param areaId is required!');
  if (!obj) throw new Error('Param obj is required!');

  if (Object.keys(validators).length === 1) extensions.clearValidationMessage(areaId, Object.keys(validators)[0]);
  else extensions.clearValidationMessages(areaId);

  binding.bind(areaId, obj);
  if (!validators) return true;
  const messages = await modelValidators.validate(obj, validators);
  if (messages) {
    extensions.showValidationMessages(areaId, messages);
    return false;
  }
  return true;
};

/**
 * Pobranie numeru wersji modułu UI
 * @param {String} [file] nazwa (ścieżka) do pliku, dla ktorego chcemy pobrac wersje modulu.
 * @example advisor-cockpit
 * @param {Object} callback Funkcja, która zostanie uruchomiona po pobraniu numeru wersji
 */
extensions.fetchModuleVersionFor = function (file) {
  const module = file.split('/')[1];

  if (MODULE_VERSIONS[module]) {
    return Promise.resolve(MODULE_VERSIONS[module]);
  }
  const ctx = document.location.pathname.split('/')[1];
  if (ctx === module) {
    MODULE_VERSIONS[module] = APP_VERSION;
    return Promise.resolve(MODULE_VERSIONS[module]);
  }
  const url = `${module}/info`;
  const abortable = false;
  return new Promise(function (resolve, reject) {
    const runCallback = function (data) {
      const version =
        data && data.version && data.svnRevision ? `${data.version}-${data.svnRevision}` : new Date().getTime();
      MODULE_VERSIONS[module] = version;
      resolve(version);
    };
    const runError = function () {
      throw new Error(`Nie powiodla sie proba pobrania numeru wersji modulu: ${module}`);
    };
    vx.ajax.getq(url, runCallback, runError, abortable);
  });
};

extensions.beforeControllerInitInterceptor = async function (controller, abortable) {
  const parametersRequest = fetchParameters(controller);
  const permissionsRequest = fetchPermissions(controller);

  return Promise.all([parametersRequest, permissionsRequest]);
};

/**
 * Komponent odpowiedzialny za pobieranie parametrow z serwera.
 * W kontrolerze wystarczy zdefiniowac strukture
 *  self.parameters = {
 *		'hr' : 'param1,param2',
 *		'customer-products' : 'param3'
 *	};
 * gdzie hr, customer-products to sciezki do mikroserwisow restowych,
 * zas 'param1,param2' to nazwy parametrow, ktore zwraca wskazany mikroserwis
 *
 * w rezultacie dzialania komponentu kontroler dostanie w czasie inicjalizacji parametry z serwera
 * params
 *
 * Przykladowe sprawdzenie uprawnienia
 *
 * self.getParameter['hr']['param1'];
 *
 */
var fetchParameters = function (controller) {
  const abortable = false;
  const { parameters } = controller;
  if (!parameters) return Promise.resolve();
  controller.getParameter = controller.getParameter || {};
  const createPromiseForKey = function (backendModule) {
    const url = `${backendModule}/gui-config/${parameters[backendModule]}?v=${extensions.getSessionId()}`;
    const setData = function (data) {
      controller.getParameter[backendModule] = data;
    };
    return ajax.get(url, null, abortable).then(setData);
  };
  const promises = Object.keys(parameters).map(createPromiseForKey);
  return Promise.all(promises);
};

/**
 * Komponent odpowiedzialny za pobieranie uprawnien z serwera.
 * W kontrolerze wystarczy zdefiniowac strukture
 *  this.permissions = {
 *		'hr' : 'PERM_1,PERM_2',
 *		'customer-products' : 'PERM_1'
 *	};
 * gdzie hr, customer-products to sciezki do mikroserwisow restowych,
 * zas 'PERM_1,PERM_2' to nazwy uprawnien rozwiazywanych przez wskazany mikroserwis
 *
 * w rezultacie dzialania komponentu w kontrolerze zostanie wypelniona struktura
 * hasPermissionTo
 *
 * Przykladowe sprawdzenie uprawnienia
 *
 * this.hasPermissionTo['hr']['PERM_1'];
 *
 */

var fetchPermissions = function (controller) {
  const abortable = false;
  const { permissions } = controller;
  if (!permissions) return Promise.resolve();
  controller.hasPermissionTo = controller.hasPermissionTo || {};

  const createPromiseForKey = function (backendModule) {
    const url = `${backendModule}/permissions/${
      permissions[backendModule]
    }?v=${extensions.permissionVersionGenerator()}`;
    const setData = function (data) {
      controller.hasPermissionTo[backendModule] = data;
    };
    return ajax.get(url, null, abortable).then(setData);
  };
  const promises = Object.keys(permissions).map(createPromiseForKey);
  return Promise.all(promises);
};

extensions.defaultContentAreaWaitHtml = `<div class="main-section-loader-wrapper">
		<div class="loader-wrapper">
			<div class="loader"></div>
			<div class="loader-section section-left"></div>
			<div class="loader-section section-right"></div>
		</div>
		</div>`;

extensions.login = function (callback) {
  login(callback);
};

export default extensions;
