import Tag from '../Tag';

export default class extends Tag {
  template(params) {
    return `
        <div class="${params.containerClass}">
            <input
                type="button"
                class="button ${params.importantb}  ${params.aclass}" ${params.disabledb} ${params.extraAttr}
                value="${params.label}"
                id="${params.idNew}"
            >
        </div>
        `;
  }

  prepareParams(o, params) {
    params.extraAttr = params.extraAttr || '';
    params.containerClass = params.containerClass || '';
  }
}
