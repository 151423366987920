
  import { modelFormatters } from '@vx/framework';

  const template = function(o, tags){
    var _e= o.encode;
    o._ids.unitListId = o.areaId + "-unitListId";

    let _s='<div class="platin-logo" id="top"> <a href="#welcome/main" id="logoLink" style="width: 120px; height: 54px;"></a> <div class="box nav-box"> <ul class="nav-box-ul" style="width: 527px;"> <li> Imię i nazwisko:&nbsp;<span title="Zalogowany pracownik: &nbsp;'+_e( o.userData.id )+'">'+_e( o.userData.firstName )+'&nbsp;'+_e( o.userData.surname )+'</span> </li> <li> Jednostka:&nbsp;<span title="'+_e( o.userData.selectedUnit.name )+'">'+_e( o.userData.selectedUnit.name )+',&nbsp;'+_e(
          o.userData.selectedUnit.statisticalUnitNumber )+'</span> <span class="tooltipMenuHandler"> <a href="javascript:;"> <span class="change"></span> </a> <div class="tooltipMenu"> <div class="tooltipHeader">Zmień jednostkę</div> <div class="tooltipBody" style="height: 300px; overflow: auto;"> <ul id="'+_e( o._ids.unitListId )+'"> '; for (let i = 0; i < o.userData.availableUnits.length; i++) {
                const unit = o.userData.availableUnits[i]; _s+=' <li> <a href="javascript:;" vx-unit-number="'+_e( unit.statisticalUnitNumber )+'"> '+_e( unit.name )+'('+_e( unit.statisticalUnitNumber )+') </a> </li> '; } _s+=' </ul> </div> </div> </span> </li> <li> Role/Obszary: &nbsp;<span title="'+_e( o.userData.selectedUnit.roles[0] )+' / BH">'+_e( o.userData.selectedUnit.roles[0] )+' / BH</span> </li> </ul> </div> <div class="boxLabel operator"></div> <div style="float: left; margin-left: 174px;"></div> </div> <div id="menu"></div> <div id="submenu2"></div> ';
    return _s;
  }; 
  template._FILEPATH = 'src/js/components/header/HeaderTemplate.vtl';
  export default template;
  