
  import { modelFormatters } from '@vx/framework';

  const template = function(o, tags){
    var _e= o.encode;
    

    let _s='<a id="messages-container-button" class="button button-gradient icon-exclamation-circle" vx-action="iconClick" title="Komunikaty"> <span id="messages-container-counter" class="bubble bubble-count">0</span> </a> <span class="dropdown"> <span class="dropdown-content"> <span id="messages-container-container" class="dropdown-items" vx-action="dispatchItemClick"> </span> <span id="messages-container-close-button" class="close-messages" vx-action="closeContainer"></span> <a id="messages-container-delete-all-button" class="button button-action icon-trash-o dismiss-messages" vx-action="deleteAllItems" title="Usuń"></a> </span> </span>';
    return _s;
  }; 
  template._FILEPATH = 'src/js/platin/controllers/message/MessagesTemplate.vtl';
  export default template;
  