
  import { modelFormatters } from '@vx/framework';

  const template = function(o, tags){
    var _e= o.encode;
    

    let _s='<div id="platin-header"></div> <div class="nav"> <div class="nav-main extended" id="nav-main-new"> <div class="nav-item nav-button has-dropdown"> <a id="nav-main-shower" class="button button-gradient icon-navicon" title="Menu"></a> <span id="nav-main-container" class="dropdown"></span> </div> </div> <div class="nav-left padding-10" id="nav2"></div> <div class="nav-right" id="nav-right"> <div class="nav-item nav-button" id="notifications-menu-icon"></div> <div id="right-menu-icons" class="nav-items-wrapper"></div> </div> </div> <div class="titlebar" id="titlebar"> <div class="fn fn-left"> <a href="#" class="button button-small tall button-back" id="back" style="display: none"> <span class="icon-arrow-left text-gray"></span> Wstecz </a> <span class="page-title" id="pageTitle"></span> </div> <div class="fn fn-right" id="titlebar-rightside-space"></div> </div>';
    return _s;
  }; 
  template._FILEPATH = 'src/js/components/SharedUITemplate.vtl';
  export default template;
  