import Tag from '../Tag';

export default class extends Tag {
  template(params) {
    return `
      <th rowspan="1" colspan="1" ${params.width} ${params.key} ${params.action} ${params.allowSort} class="${params.extraClasses}">--nestedContent--</th>
    `;
  }

  prepareParams(o, params) {
    params.width = params.width ? `width="${params.width}"` : '';
    params.extraClasses = params.extraClasses || '';
    params.key = params.key ? `vx-key="${params.key}"` : '';
    params.action = params.action ? `vx-action=${params.action}` : '';
    params.allowSort = params.allowSort ? `vx-allowSort=1` : '';
  }
}
