export default {
    displayFormat(value) {
        if (value || value === 0) {
            var patt = /(^(((0?[0-9]|1[01]):[0-5][0-9])|12:00)$)/;	//od 00:00 do 12:00
            var testRes = patt.test( value.toString() );
            var toReturnVal = '';
            if( testRes ) {
                toReturnVal = value;
            } else {
                toReturnVal = '0:00';
            }
            return toReturnVal;
        }
        return '0:00';
    },
    modelFormat(valueToFormat) {
        return valueToFormat.replace(/ /g,''); //.replace('.',',')
    }
}