import vx, {dom, utils} from '@vx/framework';

import template from './MessagesTemplate.vtl';

export default function() {
  const self = this;
  self.template = template;

  this.MAX_ITEMS = 10;

  self.init = function(params) {
    self.params = params;

    this.$parent = null;
    this.$iconButton = null;
    this.$counter = null;
    this.$container = null;
    this.$closeButton = null;
    this.$deleteButton = null;
    this.list = [];

    self.render();

    self.setupDOMElements();
    self.setupDOMEvents();
  };

  async function addMessage(message) {
    self.list.push(message);

    const areaId = `messages-message-${utils.guid()}`;
    const $item = document.createElement('div');
    $item.id = areaId;
    $item.className = `dropdown-item ${message.type} expanded`;
    self.$container.prepend($item);

    await vx.load(message.controller, {
      areaId,
      message,
    });

    self.toggleParent(true);
    self.$parent.classList.add('dropdown-visible');

    self.updateCounter();

    self.clearContainerToMaxItems();
  }

  self.messages = {
    addMessage,
  };

  self.setupDOMElements = function() {
    this.$parent = document.getElementById(this.areaId);
    this.$counter = document.getElementById(`${this.areaId}-counter`);
    this.$container = document.getElementById(`${this.areaId}-container`);
    this.$iconButton = document.getElementById(`${this.areaId}-button`);
    this.$closeButton = document.getElementById(`${this.areaId}-close-button`);
    this.$deleteButton = document.getElementById(
      `${this.areaId}-delete-all-button`,
    );
  };

  self.setupDOMEvents = function() {
    this.$parent.addEventListener(
      'click',
      this.dispatchClickHandler.bind(this),
      false,
    );
    window.addEventListener(
      'hashchange',
      this.clearContainer.bind(this),
      false,
    );
  };

  self.dispatchClickHandler = function(event) {
    const eventSrc = event.target;
    const $item = dom.findElementWithCustomAttributeInDomHierachy(
      eventSrc,
      self.$parent,
      'vx-action',
    );

    if ($item) {
      const action = $item.getAttribute('vx-action');
      self[action](eventSrc);
    }
  };

  self.iconClick = function() {
    self.$parent.classList.toggle('dropdown-visible');
  };

  self.closeContainer = function() {
    this.$parent.classList.remove('dropdown-visible');
  };

  self.hideContainer = function() {
    if (this.$container.children.length === 0) {
      this.$parent.classList.remove('dropdown-visible');
      this.toggleParent(false);
    }
  };

  this.dispatchItemClick = function(eventSrc) {
    const $item = dom.findElementWithCustomAttributeInDomHierachy(
      eventSrc,
      self.$container,
      'vx-toggle',
    );

    if ($item) {
      $item.parentElement.classList.toggle('expanded');
    }
  };

  this.updateCounter = function() {
    const count = self.list.length;

    if (count > 0) {
      this.$counter.style.removeProperty('display');
      this.$counter.innerHTML = count;
    } else {
      this.toggleParent(false);
    }
  };

  this.toggleParent = function(show) {
    this.$parent.style.setProperty('display', show ? '' : 'none');
  };

  this.deleteAllItems = function() {
    this.clearContainer();
  };

  this.clearMessages = function() {
    this.list = [];
  };

  this.clearContainer = function() {
    this.$container.innerHTML = '';
    this.clearMessages();
    this.updateCounter();
  };

  this.clearContainerToMaxItems = function() {
    const amount = this.$container.children.length;
    if (amount === this.MAX_ITEMS + 1) {
      const $child = this.$container.children[amount - 1];
      this.$container.removeChild($child);
      this.list.shift();
    }
  };
}
