import {paramsValidators} from '@vx/framework';
import Tag from '../Tag';

export default class extends Tag {
  template(params) {
    return `
      <div id="div_${params.idNew}" class="vx-input-div" ${params.styleWidth}>
        <div class="vx-input-div-inner">
          <label for="${params.name}">${params.label}</label>
          <input type="text" id="${params.idNew}" name="${params.name}" vx-wrapper-for="${params.name}" placeholder="${params.placeholder}" ${params.valueStrNew} ${params.readonlyb} ${params.formatters} />
          <span vx-error-message-for="${params.name}" class="vo-error-wrapper"></span>
        </div>
      </div>
    `;
  }

  paramsTypes() {
    return {
      name: paramsValidators.required,
    };
  }

  prepareParams(o, params) {
    params.placeholder = params.placeholder || '';
  }
}
