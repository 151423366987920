
  import { modelFormatters } from '@vx/framework';

  const template = function(o, tags){
    var _e= o.encode;
    o._ids.closeButtonId = o.areaId + "-closeButtonId";

    let _s='<div class="wrong msg-red"> <span> <img src="./images/incorrect32.png" style="float: left;"/> Wystąpił błąd aplikacji. <br/> <ul class="adminInfo" style="overflow-wrap: break-word;"> <li>Czas błędu: '+_e( o.err.date )+'</li> <li>Opis błędu: '+_e( o.err.message )+'</li> <li>Ścieżka: '+_e( o.err.url )+'</li> <li>Status: '+_e( o.err.status )+'</li> </ul> <br/> Jeżeli błąd będzie się powtarzał, prosimy o zgłoszenie do HelpDesk. <br/> Możesz przejść do strony głównej <a href="javascript:;" id="'+_e( o._ids.closeButtonId )+'">tutaj</a>. </span> </div> ';
    return _s;
  }; 
  template._FILEPATH = 'src/js/platin/controllers/popup/ErrorTemplate.vtl';
  export default template;
  