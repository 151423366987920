import { utils } from "@vx/framework";
import Tag from "../Tag";
import Controller from "./SortableTableController";

export default class extends Tag {
  template(params) {
    return `
      <div class="dataTableswrapper" id=${params.idNew}  vx-tag-component-id="${params.componentId}">
        <div class="tableHeader"></div>
        <div id="${params.idNew}-loader" class="dataTables_processing">Proszę czekać...</div>
        <table cellspacing="0" cellpadding="0" border="0" id="${params.idNew}-table" class="tbl2" style="table-layout: fixed; float: left;">
          --nestedContent--
        </table>
        <table class="tbl2" id="${params.idNew}-no-results" style="text-align: center; height: 30px;" style="display: none;">
          <tbody>
            <tr class="row1 grid">
              <td>
                <strong>Brak danych do wyświetlenia</strong>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="clear"></div>
        <div class="paging paginate" id="${params.idNew}-paginator"></div>
        <div class="clear"></div>
      </div>
    `;
  }

  prepareParams(o, params) {
    params.height = params.height || "";
    params.hideSearch = params.hideSearch || false;

    params.componentId = utils.guid();
    this.controller = Controller;
    this.controllerParams = {
      areaId: params.idNew,
      height: params.height,
      onFetch: params.onFetch,
      url: params.url
    };
  }
}
