export function formatedPhoneNumber(value, mask, numberLength){
    var phoneNumber = mask;
    var numbers = unformatedPhoneNumber(value);
    var length = numbers.length;
    if(length > numberLength){
        numbers = numbers.slice(0, numberLength);
    }
    while(length > 0){
        phoneNumber = phoneNumber.replace( /_/, numbers[0]);
        numbers = numbers.slice(1, length);
        length = numbers.length;
    }
    return phoneNumber;
}

export function unformatedPhoneNumber(value){
    return value.toString().replace(/[\D]/g, '');
}

export default {
    formatedPhoneNumber,
    unformatedPhoneNumber,

    displayFormat(value){
        var numberLength = 9;
        var mask = "___-___-___";
        return formatedPhoneNumber(value, mask, numberLength);
    },

    modelFormat(valueToFormat) {
        var value = unformatedPhoneNumber(valueToFormat);
        return value;
    }
}