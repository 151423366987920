// import {paramsValidators} from '@vx/framework';
import tagutils from './vx-tags-utils';

export default class {
  begin(o, params /* , vxName */) {
    this.params = params;
    // paramsValidators.checkParams(this.paramsTypes(), this.params, vxName);
    tagutils.decorateParams(o, this.params);
    this.prepareParams(o, this.params);
    const htmlString = this.template(this.params).replace(/\n\s+/g, ' ');
    const splitted = htmlString.split('--nestedContent--');
    [, this.endStr] = splitted;
    if (this.controller) {
      const cfg = {
        ctrl: this.controller,
        params: this.controllerParams,
      };
      o.addControllerToRun(params.componentId, cfg);
    }
    return splitted[0];
  }

  end() {
    return this.endStr;
  }

  // eslint-disable-next-line
  prepareParams(o, params) {
    // custom function to overwrite in child class
  }

  paramsTypes() {
    return {};
  }
}
