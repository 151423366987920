import accountNumberFormatter from "./formatters/accountNumberFormatter";
import callCodePhoneNumberFormatter from "./formatters/callCodePhoneNumberFormatter";
import currencyFormatter from "./formatters/currencyFormatter";
import cyferBlatTimeFormatter from "./formatters/cyferBlatTimeFormatter";
import dateFormatter from "./formatters/dateFormatter";
import decimalPrecision4Formatter from "./formatters/decimalPrecision4Formatter";
import homePhoneNumberFormatter from "./formatters/homePhoneNumberFormatter";
import integerFormatter from "./formatters/integerFormatter";
import mobilePhoneNumberFormatter from "./formatters/mobilePhoneNumberFormatter";
import numberFormatter from "./formatters/numberFormatter";
import percentFormatter from "./formatters/percentFormatter";
import percentPrecision3Formatter from "./formatters/percentPrecision3Formatter";
import percentPrecision4Formatter from "./formatters/percentPrecision4Formatter";
import percentWithoutSignFormatter from "./formatters/percentWithoutSignFormatter";
import phoneFormatter from "./formatters/phoneFormatter";
import separatorsOnlyFormatter from "./formatters/separatorsOnlyFormatter";

export default {
  accountNumberFormatter,
  callCodePhoneNumberFormatter,
  currencyFormatter,
  cyferBlatTimeFormatter,
  dateFormatter,
  decimalPrecision4Formatter,
  homePhoneNumberFormatter,
  integerFormatter,
  mobilePhoneNumberFormatter,
  numberFormatter,
  percentFormatter,
  percentPrecision3Formatter,
  percentPrecision4Formatter,
  percentWithoutSignFormatter,
  phoneFormatter,
  separatorsOnlyFormatter,

  customStringFormatter: {
    mobilePhoneNumber(country, number) {
      if (!country || !number) return '';
      return `+${country} ${number.replace(
        /(\d{3})(\d{3})(\d{3})/,
        '$1 $2 $3',
      )}`;
    },
    modelFormat(value) {
      return value.toUpperCase();
    },
  },

  hostLowerTextFormatter: {
    displayFormat: function formatCurrency(value) {
      return String(value).toLowerCase();
    },

    modelFormat(valueToFormat) {
      return String(valueToFormat).toLowerCase();
    },
  },


};
