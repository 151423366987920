import vx, { $I, ajax, utils, validate } from '@vx/framework';

const FetchableTableModel = function FetchableTableModel() {
  const self = this;
  let url;
  let afterFetchCallback;
  let total;
  let model;
  let errHandler;

  self.init = function init(params) {
    validate.notNull(params.url, 'Nie podano url do pobrania danych');
    url = params.url;
    afterFetchCallback = params.afterFetchCallback;
    errHandler = params.errHandler;
    return self;
  };

  self.fetch = function fetch(filters) {
    errHandler(ajax.get(url, filters).then(self.afterFetch));
  };

  self.getTotal = function getTotal() {
    return total;
  };

  self.getModel = function getModel() {
    return model;
  };

  self.afterFetch = function afterFetch(data) {
    total = data.maxResults;
    model = data.list;
    afterFetchCallback();
  };
};

const TableRenderer = function TableRenderer({ areaId, parentController }) {
  const self = this;
  const $body = $I(areaId).querySelector('tbody');
  const $noResults = $I(`${areaId}-no-results`);
  let rowStr = $body.innerHTML;
  rowStr = rowStr.replace(/{{/g, '{%');
  rowStr = rowStr.replace(/}}/g, '%}');
  rowStr = `{% var len = o.items.length; for (let i=0; i < len; i++){ const row = o.items[i]; const lp = i + 1 + o.pageOffset; %}${rowStr}{% } %}`;
  rowStr = utils.decode(rowStr);

  self.render = function render(items) {
    if (items.length < 1) {
      $body.innerHTML = '';
      $noResults.style.display = 'table';
      return;
    }
    $noResults.style.display = 'none';

    const pageOffset = (parentController.filters.pageNumber - 1) * parentController.filters.pageSize;
    const o = {
      items,
      parentController,
      pageOffset,
    };

    const headers$ = Array.from($I(areaId).querySelectorAll('th')).filter((el) => el.hasAttribute('vx-allowSort'));

    headers$.forEach((el) => {
      el.className = 'sorting';
    });

    const key = parentController.filters['orderBy.property'];
    const isAsc = parentController.filters['orderBy.asc'] === 'true';

    headers$
      .filter((el) => el.hasAttribute('vx-key') && el.getAttribute('vx-key') === key)
      .forEach((el) => {
        el.className = isAsc ? 'sorting_asc' : 'sorting_desc';
      });

    const rows = vx.generateTemplate(rowStr, o);
    $body.innerHTML = rows;
  };

  self.drawPaginator = function drawPaginator(itemsLength, total) {
    const container$ = $I(`${areaId}-paginator`);
    if (itemsLength < 1) {
      container$.style.display = 'none';
      return;
    }
    container$.style.display = 'block';
    const { pageNumber, pageSize } = parentController.filters;
    const totalPages = Math.ceil(total / pageSize);
    const info = `<div class="dataTables_info">Pozycje od <b>${
      (pageNumber - 1) * pageSize + 1
    }</b> do <b>${itemsLength}</b> z <b>${total}</b></div>`;
    const firstPage = 1;
    const previousPage = pageNumber - 1;
    const nextPage = pageNumber + 1;
    let content = '';
    const firstPageButton = `<span class="first paginate_button ${
      pageNumber === firstPage ? 'sprite-rr50 disabled' : 'sprite-rr'
    }" vx-pagenum="${firstPage}">&nbsp;</span>`;
    const previousPageButton = `<span class="previous paginate_button ${
      previousPage < firstPage ? 'sprite-r50 disabled' : 'sprite-r'
    }" vx-pagenum="${previousPage}">&nbsp;</span>`;
    const lastPageButton = `<span class="last paginate_button ${
      pageNumber === totalPages ? 'sprite-ff50 disabled' : 'sprite-ff'
    }" vx-pagenum="${totalPages}">&nbsp;</span>`;
    const nextPageButton = `<span class="next paginate_button ${
      nextPage > totalPages ? 'sprite-f50 disabled' : 'sprite-f'
    }" vx-pagenum="${nextPage}">&nbsp;</span>`;

    for (let i = firstPage; i <= totalPages; i++) {
      const el = `<span class="${
        pageNumber === i ? 'paginate_active' : 'paginate_button'
      }" vx-pagenum=${i}>${i}</span>`;
      content += el;
    }

    const innerHTML = `<div class="dataTables_info">${info}</div><div class="dataTables_paginate paging_full_numbers">${firstPageButton}${previousPageButton}<span>${content}</span>${nextPageButton}${lastPageButton}</div><div class="clear"></div>`;

    container$.innerHTML = innerHTML;
  };

  self.showLoader = function showLoader() {
    const loader = $I(`${areaId}-loader`);
    loader.style.visibility = 'unset';
  };

  self.hideLoader = function hideLoader() {
    const loader = $I(`${areaId}-loader`);
    loader.style.visibility = 'hidden';
  };
};

export default function SortableTableController() {
  const self = this;
  let parentController;
  let tableModel;
  let renderer;
  self.template = '';

  function refreshMessage() {
    renderer.showLoader();
    tableModel.fetch(parentController.filters);
  }

  self.init = function init(params) {
    parentController = params.parentController;
    renderer = new TableRenderer({ areaId: params.areaId, parentController });
    renderer.render([]);
    tableModel = new FetchableTableModel().init({
      url: params.url,
      afterFetchCallback: self.renderNew.bind(self),
      errHandler: (promise) => {
        self.app.to(promise, renderer.hideLoader);
      },
    });
    renderer.showLoader();
    tableModel.fetch(parentController.filters);
    self.events();
  };

  self.events = function events() {
    self.addEvent($I(self.areaId), 'click', self.clickHandler);
  };

  self.messages = {
    refresh: refreshMessage,
  };

  self.renderNew = function renderNew() {
    renderer.render(tableModel.getModel());
    renderer.drawPaginator(tableModel.getModel().length, tableModel.getTotal());
    renderer.hideLoader();
  };

  self.sortTable = function sortTable(target) {
    if (!target.hasAttribute('vx-allowSort')) return;
    const { filters } = parentController;
    const key = target.getAttribute('vx-key');
    if (filters['orderBy.property'] === key) {
      if (filters['orderBy.asc'] === 'true') {
        filters['orderBy.asc'] = 'false';
      } else {
        filters['orderBy.asc'] = 'true';
      }
    } else {
      filters['orderBy.property'] = key;
      filters['orderBy.asc'] = 'true';
    }

    refreshMessage();
  };

  self.clickHandler = function clickHandler({ target }) {
    // handle pagination
    const newPage = target.getAttribute('vx-pagenum');

    if (newPage && !target.classList.contains('disabled')) {
      parentController.filters.pageNumber = +newPage;
      refreshMessage();
      return;
    }

    if (target.hasAttribute('vx-action')) {
      const action = target.getAttribute('vx-action');
      if (typeof self[action] === 'function') {
        self[action](target);
      }
    }
  };
}
