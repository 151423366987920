
// newIEDate is function safe to use with IE web browser
function newIEDate(dateStr) {
    let [year, month, day] = (dateStr || '').split('-', 3);
    return new Date(parseInt(year), parseInt(month)-1, parseInt(day), 0, 0, 0);
}

export default {
    displayFormat: function(date) {
        var mm = date.getMonth() + 1;
        var dd = date.getDate();
        return [date.getFullYear(),
            (mm>9 ? '' : '0') + mm,
            (dd>9 ? '' : '0') + dd
        ].join('-');
    },

    modelFormat(value) {
        return newIEDate(value);
    }
}