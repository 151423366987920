import Tag from '../Tag';

export default class extends Tag {
  template(params) {
    return `
      <td class=${params.vxClass} vx-table-action="${params.parentAction}">--nestedContent--</td>
    `;
  }

  prepareParams(o, params) {
    params.parentAction = params.parentAction || '';
  }
}
