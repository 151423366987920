import template from './SharedUITemplate.vtl';
import vx from "@vx/framework";

import FooterController from "./footer/FooterController";
import HeaderContorller from "./header/HeaderController";

export default function SharedUIController() {
  this.template = template;
  const self = this;
  self.canRemoveEvents = false;
  this.init = function init() {
    vx.load(FooterController, { areaId: "footer", abortable: false });
    vx.load(HeaderContorller, {
      areaId: "head",
      abortable: false
    });
  };
}
