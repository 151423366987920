import {utils} from '@vx/framework';
import Controller from './ExpandBoxController';
import Tag from '../Tag';

export default class extends Tag {
  template(params) {
    return `
      <div class="${params.containerClass}" id="${params.idNew}-container" style="height: ${params.maxHeight}; ${params.styles}" vx-tag-component-id="${params.componentId}">
        <div>
          <h2>${params.header}</h2>
          <div class="open" id="${params.idNew}-icon">&nbsp;</div>
        </div>
        <div style="display: block;" id=${params.idNew}-content>
          --nestedContent--
        </div>
      </div>
    `;
  }

  prepareParams(o, params) {
    params.styles = params.styles ?? '';
    params.componentId = utils.guid();
    this.controller = Controller;
    this.controllerParams = {
      areaId: params.idNew,
      maxHeight: params.maxHeight,
      minHeight: params.minHeight,
    };
  }
}
