
  import { modelFormatters } from '@vx/framework';

  const template = function(o, tags){
    var _e= o.encode;
    o._ids.logoutButtonId = o.areaId + "-logoutButtonId";

    let _s='<div id="menu-left"> <ul> '; 
      for (let i=0; i < o.menuItems.length; i++) { 
        const item = o.menuItems[i];
        if(!item.available) continue;
        const activeClass = item.active ? 'current' : ''; 
        var href =  o.app.navigation.currentEnvironment + item.href;
    _s+=' <li class="'+_e( activeClass )+'"> <a href="'+_e( href )+'">'+_e( item.label )+'</a> </li> '; } _s+=' </ul> </div> <div id="menu-right"> <ul> <li> <a style="padding: 8px;" href="javascript:;"> <span title="Szybkie linki" class="link"></span> </a> </li> <li> <a style="padding: 8px;" href="javascript:;"> <span title="Wyloguj" class="exit" id="'+_e( o._ids.logoutButtonId )+'"></span> </a> </li> </ul> </div> <div id="nav-right"></div> ';
    return _s;
  }; 
  template._FILEPATH = 'src/js/components/navigation/NavigationTemplate.vtl';
  export default template;
  