/* eslint-disable */
import {utils, modelFormatters} from '@vx/framework';

const tagutils = {};

const varPlaceholderPattern = /(\${(\S*?)})/g;

tagutils.decorateParams = function(o, params) {
  params.xAttributes = '';
  params.maxLengthStr = params.maxLength
    ? `maxlength="${params.maxLength}"`
    : '';
  params.formatterStr = params.formatter
    ? `vx-formatter="${params.formatter}"`
    : '';
  params.formatOnKeyupStr = params.formatOnKeyup
    ? `vx-format-on-keyup="true"`
    : '';
  params.formatOnChangeStr = params.formatOnChange
    ? `vx-format-on-change="true"`
    : '';
  params.requiredb = params.required ? 'required' : '';
  params.requiredClass = params.required ? 'class="required"' : '';
  params.readonlyb = params.readonly ? 'readonly disabled' : '';
  params.dontBindb = params.dontBind ? 'vx-dont-bind=true' : '';
  params.styleWidth = params.width ? `style="width: ${params.width}"` : '';
  params.disabledb = params.disabled ? 'disabled=true' : '';
  params.placeholder = params.placeholder || '';
  params.aclass = params.aclass || '';
  params.placeholderStr = params.placeholder
    ? `placeholder="${params.placeholder}"`
    : '';
  params.tooltipStr = params.tooltip
    ? tagutils.generateTooltip(params.tooltip)
    : '';
  params.platinTooltipStr = params.tooltip
    ? tagutils.generatePlatinTooltip(params.tooltip)
    : '';
  params.valueStr = tagutils.calculateValueStr(o, params);
  params.valueStrNew = tagutils.extractValue(o, params);
  params.idNew = tagutils.extractId(o, params);
  params.formatters = `${params.formatterStr} ${params.formatOnKeyupStr} ${params.formatOnChangeStr}`;
  params.importantb = params.important ? 'vo-button-important' : '';
  params.label = params.label || '';
  if (!params.id && params.name) params.id = params.name.replace(/\./g, '-');
  // atrybuty z prefiksem data przepiszą się na wprost
  Object.keys(params).forEach(function(key) {
    if (key.indexOf('data') == 0) {
      params.xAttributes += ` ${key}=${params[key]}`;
    }
  });
};

tagutils.generateTooltip = function(text) {
  return (
    `${'<i class="tooltip-ico">' +
      '<div class="tooltip-ico__container">' +
      '<p> '}${text}</p>` +
    `</div>` +
    `</i>`
  );
};

tagutils.generatePlatinTooltip = function(text) {
  return `<span class="vo-label-tooltip"><span class="vo-label-tooltiptext">${text}</span></span>`;
};

tagutils.nameToId = function(input) {
  return input
    .replace(/\./g, '_')
    .replace(/\[/g, '_')
    .replace(/\]/g, '_');
};
tagutils.extractId = function(o, params) {
  if (params.vid) return `${o.areaId}-${params.vid}`;
  if (params.id) return params.id;
  if (params.name)
    return `${o && o.areaId ? o.areaId : 'unknownArea'}-${tagutils.nameToId(
      params.name,
    )}`;
};
tagutils.extractValue = function(o, params) {
  if (params.value) return `value="${params.value}"`;
  return tagutils.calculateValueStr(o, params);
};

tagutils.calculateValueStr = function(o, params) {
  if (!o.data || !params.name) return '';
  let value = utils.getPropertyOrNull(o.data, params.name);
  if (value == null) return '';
  if (params.formatter)
    value = modelFormatters[params.formatter].displayFormat(value);
  return `value="${value}"`;
};

tagutils.calculateValueStrByName = function(o, propertyName) {
  const value = utils.getPropertyOrNull(o.data, propertyName);
  return value ? `value="${value}"` : '';
};

tagutils.genAttribByName = function(o, propertyName, attribName) {
  const value = utils.getPropertyOrNull(o.data, propertyName);
  if (value) return `${attribName}="${value}"`;
  return false;
};

tagutils.replacePlaceholders = function(template, params) {
  const replaceSinglePlaceholder = function(str, str0, str1) {
    const result = params[str1] ? params[str1] : '';
    return result;
  };
  return template.replace(varPlaceholderPattern, replaceSinglePlaceholder);
};

export default tagutils;
