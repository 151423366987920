export default {
    displayFormat: function (value) {
        if (value || value === 0) {
            value = value.toString();
            value = value.replace(/[^\d,.-]/g, ''); // usun wszytko rozne od: cyfry, przecinek, kropka, minus
            value = value.replace(/%/g, ''); // usun znak procentu
            value = value.replace(',', '.');
            var prepared = Number(value.toString()).toFixed(2);
            if (prepared !== 'NaN') {
                return prepared.replace('.', ',') + '%';
            } else {
                return '0,00%';
            };
        }
        return '%';
    },

    modelFormat: function (valueToFormat) {
        return valueToFormat.replace(/%/g, '').replace(/,/g, '.');
    }
}