export default {
    displayFormat : function(value) {
        if (value || value === 0) {
            var strValue = value.toString();  //zamiana na string (w sytuacji, gdy na wejściu mamy liczbę a nie string).
            strValue = strValue.replace(/[^\d]/g, ''); //usuwa wszystko oprocz cyfr
            if(!strValue) return '';
            var accountNumber = '';
            for (var i = 1; i < strValue.length; i++) {
                accountNumber += strValue[i-1];
                if (i%4 == 2) {
                    accountNumber += ' ';
                }
            }
            accountNumber += strValue[strValue.length-1];
            return accountNumber;
        }
        return '';
    },

    modelFormat : function(value) {
        return value.replace(/ /g,''); //.replace('.',',')
    }
}