import {utils} from '@vx/framework';
import Tag from '../Tag';
import Controller from '../controllers/SimpleTableFetchableController';

export default class extends Tag {
  template(params) {
    return `
      <div id=${params.idNew}>
        <div class="light-result-container ${params.extraClasses}" vx-tag-component-id="${params.componentId}">
          <table class="light-result-table tbl2">
            <caption>${params.title}</caption>
            <tbody id="${params.idNew}-body">
              --nestedContent--
            </tbody>
          </table>
        </div>
        <div id="${params.idNew}-paginator" class="pager">
        </div>
      </div>
    `;
  }

  prepareParams(o, params) {
    params.height = params.height || '';
    params.hideSearch = params.hideSearch || false;

    params.componentId = utils.guid();
    this.controller = Controller;
    this.controllerParams = {
      areaId: params.idNew,
      height: params.height,
      onFetch: params.onFetch,
      url: params.url,
      tableModelProperty: params.tableModelProperty,
      registerAPI: params.registerAPI,
      checkable: params.checkable,
    };

    o.tableParams = {
      id: params.idNew,
      height: params.height,
      checkable: params.checkable,
      hideSearch: params.hideSearch,
    };
  }
}
