/* eslint-disable */

import {getAbsoluteUrl} from "./navigation";
import {ajax} from "@vx/framework";

export const getLogoutPageUrl = function () {
  return getAbsoluteUrl("host/logout.html");
};

export const getLoginPageUrl = function (redirectUrl) {
  return getAbsoluteUrl(
    `api/light/cas-login?redirectTo=${encodeURIComponent(redirectUrl)}`
  );
};

export const clearSession = function () {
  sessionStorage.removeItem("platinEncodedToken");
  sessionStorage.removeItem("platinToken");
  sessionStorage.removeItem("user");
};

export const verifyToken = function () {
  return ajax
    .get("light/brokers/permissions-dictionary", null, false)
    .catch((e) => {
      if (e.status === 403 || e.status === 401 || e.status === 500) {
        clearSession();
        window.location.assign(getLogoutPageUrl())
      }
    });
};

const login = function (callback) {
  if (!sessionStorage.platinToken) {
    clearSession();
    window.location.assign(getLoginPageUrl(document.location.href));
    return false;
  }

  if (!sessionStorage.platinEncodedToken) {
    const {platinToken} = sessionStorage;
    const platinEncodedToken = window.atob(platinToken.split(".")[0]);
    const loginDate = new Date();

    sessionStorage.setItem("platinEncodedToken", platinEncodedToken);
    sessionStorage.setItem("loginTime", loginDate.getTime());
    if (callback) callback();
  }
  return true;
};

// ta funkcja powinna chyba zostać przeniesiona do template
function checkIfTokenExpired() {
  if (sessionStorage.platinEncodedToken) {
    const token = JSON.parse(sessionStorage.platinEncodedToken);
    const tokenTime = token.expires;
    const currentTime = new Date().getTime();
    if (currentTime > tokenTime) {
      clearSession();
      window.location.assign(getLogoutPageUrl());
    }
    verifyToken();
  }
}

let checkTokenInterval;

export default function init() {
  // if missing platinEncoded token to zwraca isSessionValid == true
  if (sessionStorage.platinEncodedToken) {
    const token = JSON.parse(sessionStorage.platinEncodedToken);
    const tokenTime = token.expires;
    const currentTime = new Date().getTime();
    if (currentTime > tokenTime - 120000) {
      // 120 000ms == 2min
      clearSession();
    }
  }
  // wymuszamy sprawdzenie czy uzytkownik jest zalogowany
  const isSessionValid = login();

  if (!checkTokenInterval) {
    window.setTimeout(verifyToken, 1000);
    checkTokenInterval = window.setInterval(checkIfTokenExpired, 60000); // check every minute
  }

  return isSessionValid;
}
export {login};
