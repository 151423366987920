import vx from "@vx/framework";
import Template from "./NavigationTemplate.vtl";
import rootNavigationDefinition from "./root-navigation-definition";
import SecondaryNavigationController from "../secondary-navigation/SecondaryNavigationController";
import * as Permissions from "../../permissions";

export default function NavigationController() {
  const self = this;
  self.template = Template;
  self.parameters = {
    light: "casurl"
  };
  self.canRemoveEvents = false;
  self.permissions = {
    light: `${Permissions.LIGHT_ELSA},${Permissions.LIGHT_INFORMATION_MENU},${Permissions.LIGHT_REPORTS_MENU},${Permissions.LIGHT_MANAGEMENT_MENU},${Permissions.LIGHT_ESO_EMPLOYEES},${Permissions.LIGHT_COOPERATING_ESOS}`
  };

  let isSomeElActive = false;

  function getUrlHash(url) {
    const hashIndex = [...url].findIndex(el => el === "#");
    if (hashIndex > -1) {
      return url.substring(hashIndex, url.length);
    }
    return "";
  }

  function findActiveElement(menuData) {
    if (Array.isArray(menuData)) {
      menuData.forEach(el => findActiveElement(el));
      return menuData.some(el => el.active);
    }

    const currentHash = window.location.hash;
    let isActive = false;

    if (!Object.prototype.hasOwnProperty.call(menuData, "available")) {
      menuData.available = true;
    }
    if (menuData.children) {
      isActive =
        findActiveElement(menuData.children) ||
        getUrlHash(menuData.href) === currentHash;
      menuData.active = isActive;
      if (isActive) isSomeElActive = true;
    } else {
      isActive = getUrlHash(menuData.href) === currentHash;
      menuData.active = isActive;
      if (isActive) isSomeElActive = true;
    }
    return false;
  }

  function onLogoutButtonClick() {
    self.app.clearSession();
    const casLogoutUrl = `${
      self.getParameter.light.casurl
    }/logout?service=${encodeURIComponent(self.app.getLogoutPageUrl())}`;
    window.location = casLogoutUrl;
  }

  function userHasPermission(permission) {
    return self.hasPermissionTo.light[permission];
  }

  self.init = function init() {
    self.menuItems = rootNavigationDefinition({userHasPermission});
    window.addEventListener("hashchange", () => {
      isSomeElActive = false;
      findActiveElement(self.menuItems);
      if (!isSomeElActive) {
        self.menuItems[0].active = true;
      }
      self.sendMessage(
        "submenu2",
        "refreshSecondaryNav",
        self.menuItems.find(el => el.active).children || []
      );
      self.render();
    });

    findActiveElement(self.menuItems);

    if (!isSomeElActive) {
      self.menuItems[0].active = true;
    }

    vx.load(SecondaryNavigationController, {
      areaId: "submenu2",
      menuData: self.menuItems.find(el => el.active).children || []
    });
    self.render();
  };

  self.events = {
    "#logoutButtonId click": onLogoutButtonClick
  };
}
