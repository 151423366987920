/* eslint-disable */
import {$I, dom, validate} from '@vx/framework';
import template from './DatepickerTemplate.vtl';

function isDayOutOfRange(dayTS, minDateTS, maxDateTS) {
  return !!(
    (minDateTS && dayTS < minDateTS) ||
    (maxDateTS && maxDateTS < dayTS)
  );
}

export default function() {
  const self = this;
  this.containerId = '';
  this.template = template;
  let dateInputElement;
  let hideFunction;
  let $input;
  let $container;
  const documentBody = document.body;
  self.hidden = true;
  self.headerId;
  self.gridId;

  self.init = function(params) {
    self.containerId = `${self.areaId}-container`;
    self.headerId = `${self.areaId}-header`;
    self.gridId = `${self.areaId}-grid`;
    $input = $I(params.inputId);
    dateInputElement = $I(params.inputId);

    const inputDate = dateInputElement.value;
    self.model = new DatePickerModel({
      dateString: inputDate,
      minDate: params.minDate,
      maxDate: params.maxDate,
    });
    self.isTodayOutOfRange = self.model.isTodayOutOfRange();
    self.render();
    assignToggleEvent();
    $container = $I(self.containerId);
  };

  function assignToggleEvent() {
    if ($input) {
      self.addEvent($input, 'click', toggle);
    }
  }

  function renderOnShow() {
    const inputDate = dateInputElement.value;
    self.model.calcDateFromInput(inputDate);

    self.render();
    $container = $I(self.containerId);
  }

  function toggle() {
    if (self.hidden) show();
    else self.hide();
  }

  self.hide = function() {
    $container.style.display = 'none';
    self.hidden = true;
    removeCloseEvent();
  };

  function show() {
    renderOnShow();
    $container.style.display = 'block';
    self.hidden = false;
    assignCloseEvent();
  }

  var assignCloseEvent = function() {
    // zapamietujemy referencje do funkcji obslugi eventu zeby ja pozniej zgrabnie zdjac
    // mousedown nasteouje przed click, ktore jest obslugiwane przez ten kontroler
    // dzieki czemu nie ma wyscigu i referowania do elementow, ktore kontroler usunal/przerysowal
    hideFunction = self.addEvent(documentBody, 'mousedown', hideIfNeccesary);
  };

  var removeCloseEvent = function() {
    self.removeEvent(documentBody, 'mousedown', hideFunction);
  };

  // chowanie datepicker po kliknieciu w obszar poza nim
  var hideIfNeccesary = function(event) {
    // sprawdzamy czy klikniecie pochodzi z wnetrza DatePicker
    if ($container.contains(event.target)) return;
    if ($input && $input.contains(event.target)) return;
    self.hide();
  };

  const clickDispatcher = function(event) {
    const {target} = event;
    const action = target.getAttribute('vx-dp-action');
    // interesują nas tylko elementy z atrybutem action
    if (action === null) return;
    // wywolanie metody kontrolera o nazwie odpowiadajacej wartosci atrybutu "action" tagu "a"
    if (self.model[action]) {
      // jesli akcja ewidentnie dotyczy modelu, delegujemy ja od razu
      self.model[action]();
      self.render();
      $container = $I(self.containerId);
    } else {
      // jesli trzeba cos wyluskac z elementu HTML, na ktory kliknieto- delegujemy to do kontrolera
      self[action](target);
    }
  };

  self.setDay = function(htmlElement) {
    const val = htmlElement.getAttribute('vx-dp-date');
    self.model.onDayPicked(val);
    self.submit();
  };

  self.setToday = function() {
    self.model.onToday();
  };

  self.submit = function() {
    dateInputElement.value = self.model.getPickedDate();
    dom.fireDOMEvent(dateInputElement, 'change');
    dom.fireDOMEvent(dateInputElement, 'blur');
    self.hide();
  };

  self.events = {
    '#containerId click': clickDispatcher,
  };

  // wsadzilem model do kontrolera- to jest przygotowanie sie do uwolnienia od jetty i wprowadzenia
  // globalnych nazw kontolerow i ladowania modulow po nowemu
  /*= ===========================================================================================
         * Model dla kalendarzyka
         ============================================================================================= */
  var DatePickerModel = function(params) {
    const self = this;

    let date; // ustawiona data, na której operujemy rysujac ekrany
    let pickedDate; // data wybrana przez uzytkownika, poczatkowo rowna wartosci date
    const MONTHS = {
      0: 'Styczeń',
      1: 'Luty',
      2: 'Marzec',
      3: 'Kwiecień',
      4: 'Maj',
      5: 'Czerwiec',
      6: 'Lipiec',
      7: 'Sierpień',
      8: 'Wrzesień',
      9: 'Październik',
      10: 'Listopad',
      11: 'Grudzień',
    };
    let minDateTS;
    let maxDateTS;

    self.init = function(params) {
      let minDate, maxDate;

      if (!params.minDate) minDate = null;
      else {
        minDate = new Date(params.minDate);
        if (isNaN(minDate.getTime())) minDate = null;
        else minDate.setHours(0, 0, 0);
      }

      if (!params.maxDate) maxDate = null;
      else {
        maxDate = new Date(params.maxDate);
        if (isNaN(maxDate.getTime())) maxDate = null;
        else maxDate.setHours(23, 59, 59);
      }

      minDateTS = minDate && minDate.getTime();
      maxDateTS = maxDate && maxDate.getTime();

      self.calcDateFromInput(params.dateString);
    };

    self.calcDateFromInput = function(dateString) {
      if (!dateString) date = new Date();
      else {
        date = new Date(dateString);
        if (isNaN(date.getTime())) date = new Date();
      }

      pickedDate = new Date(date.getTime());

      if (minDateTS || maxDateTS) {
        const pickedDateTS = pickedDate.getTime();

        if (minDateTS && pickedDateTS < minDateTS) {
          pickedDate.setFullYear(
            minDate.getFullYear(),
            minDate.getMonth(),
            minDate.getDate(),
          );
        }
        if (maxDateTS && maxDateTS < pickedDateTS) {
          pickedDate.setFullYear(
            maxDate.getFullYear(),
            maxDate.getMonth(),
            maxDate.getDate(),
          );
        }
      }
    };

    self.onPrevYear = function() {
      date.setFullYear(date.getFullYear() - 1);
    };

    self.onPrevMonth = function() {
      date.setMonth(date.getMonth() - 1);
    };

    self.onToday = function() {
      date = new Date();
      pickedDate.setFullYear(date.getFullYear());
      pickedDate.setMonth(date.getMonth());
      pickedDate.setDate(date.getDate());
    };

    self.onNextMonth = function() {
      date.setMonth(date.getMonth() + 1);
    };

    self.onNextYear = function() {
      date.setFullYear(date.getFullYear() + 1);
    };

    const dateFromString = function(dateString) {
      validate.notNull(dateString, 'Nalezy podac lancuch znakow');
      const dateAndTime = dateString.split(' ');
      const date = dateAndTime[0];
      const splittedDate = date.split('-');
      const year = splittedDate[0];
      const month = parseInt(splittedDate[1]) - 1;
      const day = splittedDate[2];
      const time = dateAndTime.length > 1 ? dateAndTime[1] : null;
      if (!time) return new Date(year, month, day);
      const splittedTime = time.split(':');
      const hour = splittedTime[0];
      const minute = splittedTime[1];
      return new Date(year, month, day, hour, minute);
    };

    /*
     * Wybrano nowa date przez klikniecie w siatke dni
     */
    self.onDayPicked = function(day) {
      pickedDate = dateFromString(day);
    };
    /*
     * Pobranie miesiąca i roku (żeby wyświetlić w nagłówku)
     */
    self.getMonthAndYear = function() {
      return `${MONTHS[date.getMonth()]} ${date.getFullYear()}`;
    };

    /*
     * Pobranie listy dni, która powinna być widoczna na siatce kalendarza
     * Zaczyna się od poniedziałku kończy na niedzieli
     * Jesli miesiąc zaczyna się w innym dniu niż poniedziałek, poprzedzające dni będą wypełnione numerami dni poprzedniego miesiąca
     */
    self.getDays = function() {
      const daysOnGrid = new DaysOnGrid(date, pickedDate, minDateTS, maxDateTS);
      const days = daysOnGrid.getDays();
      return days;
    };

    /*
     * Zwrocenie wybranej wartosci w formacie YYYY-mm-dd HH:MM
     */
    self.getPickedDate = function() {
      return getFormattedDate(pickedDate);
    };

    var getFormattedDate = function(date) {
      let day = date.getDate();
      if (day < 10) day = `0${day}`;
      let month = date.getMonth() + 1;
      if (month < 10) month = `0${month}`;
      return `${date.getFullYear()}-${month}-${day}`;
    };

    this.isTodayOutOfRange = function() {
      var todayTS = Date.now();
      return isDayOutOfRange(todayTS, minDateTS, maxDateTS);
    };

    // inicjalizacja obiektu na podstawie danych przekazanych do funkcji konstruktora
    self.init(params);
  };

  /*= ===========================================================================================
         * Obiekt reprezentującu listę dni widocznych na siatce kalendarza
         ============================================================================================= */
  var DaysOnGrid = function(chosenDate, pickedDate, minDateTS, maxDateTS) {
    const self = this;
    const days = [];
    const date = chosenDate;
    const today = new Date();
    let monthDays = 0;

    /*
     * Czy podany dzień to poniedzialek
     */
    const isMonday = function(day) {
      return day.getDay() == 1;
    };

    /*
     * Oblicz od jakiego dnia pokażemy siatkę miesiąca (zaczynamy od poniedziałku)
     */
    const calculateStartingDate = function() {
      const firstDayOfChosenMonth = new Date(
        date.getFullYear(),
        date.getMonth(),
        1,
      );
      const startDay = firstDayOfChosenMonth;
      // liczymy ilosc dni w miesiacu zeby nie rysowac nastepnego miesiaca
      monthDays = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        0,
      ).getDate();
      // cofamy się do pierwszego napotkanego poniedziałku
      while (!isMonday(startDay)) {
        startDay.setDate(startDay.getDate() - 1);
        monthDays += 1;
      }
      return startDay;
    };

    /*
     * Dodaj dzień do tablicy dni
     */
    const add = function(day) {
      const otherMonth = day.getMonth() != date.getMonth();
      const isToday = compareDates(day, today);
      const isSelected = compareDates(day, pickedDate);
      const isDisabled = isDayOutOfRange(day.getTime(), minDateTS, maxDateTS);

      days.push({
        otherMonth,
        isToday,
        isSelected,
        isDisabled,
        day: day.getDate(),
        weekDay: day.getDay(),
        date: getFormattedDate(day),
      });
    };

    var getFormattedDate = function(date) {
      let day = date.getDate();
      if (day < 10) day = `0${day}`;
      let month = date.getMonth() + 1;
      if (month < 10) month = `0${month}`;
      return `${date.getFullYear()}-${month}-${day}`;
    };

    const compareDates = function(date1, date2) {
      return (
        date1.getDate() == date2.getDate() &&
        date1.getMonth() == date2.getMonth() &&
        date1.getFullYear() == date2.getFullYear()
      );
    };

    const init = function() {
      const day = calculateStartingDate();
      add(day);
      // linijke wyzej jest dodawany najbliższy poniedzialek wiec musimy odjac 1 z puli dni
      monthDays -= 1;
      for (let i = 0; i < monthDays; i++) {
        day.setDate(day.getDate() + 1);
        add(day);
      }
    };

    /*
     * Zwróć obliczoną tablicę dni
     */
    self.getDays = function() {
      init();
      return days;
    };
  };
}
