import {$I} from '@vx/framework';

export default function ExpandBoxController() {
  const self = this;
  let maxHeight;
  let minHeight;
  let icon$;

  function closeContent() {
    const container = $I(`${self.areaId}-container`);
    const content = $I(`${self.areaId}-content`);
    container.style.height = minHeight;
    icon$.classList.remove('open');
    icon$.classList.add('close');
    content.style.display = 'none';
  }

  function openContent() {
    const container = $I(`${self.areaId}-container`);
    const content = $I(`${self.areaId}-content`);
    container.style.height = maxHeight;
    icon$.classList.remove('close');
    icon$.classList.add('open');
    content.style.display = 'block';
  }

  function toggleContent() {
    if (icon$.classList.contains('open')) {
      closeContent();
    } else {
      openContent();
    }
  }

  function events() {
    self.addEvent(icon$, 'click', toggleContent);
  }

  self.init = function init(params) {
    maxHeight = params.maxHeight;
    minHeight = params.minHeight;
    icon$ = $I(`${self.areaId}-icon`);
    events();
  };
}
