/* eslint-disable */
/*
 * Wersja kontrolera SimpleTable, która potrafi sama pobrać dane z serwera na podstawie URL
 */
import vx, { $I, ajax, dom, to, utils, validate } from "@vx/framework";

export default function() {
  const self = this;
  self.template = "";

  let height;
  let tableModel;
  let parentController;
  let rowIdProperty;
  let sortBy;
  let renderer;
  let $area;
  let $viewport;
  let $sortSelect;
  let $searchInput;
  let lastSearchString;
  let scrollbarController;
  const ENTER = 13;
  let fetchingMode = false;
  let onFetch;
  let checkeds;
  let isCheckedAll = false;
  let pending = true;
  let templateParams;

  this.events = function() {
    this.addEvent($I(this.areaId), "click", this.onClickHandler);
    if ($sortSelect)
      this.addEvent($sortSelect, "change", this.onSortSelectChange);
    if ($searchInput) {
      this.addEvent($searchInput, "keydown", this.onSearchInputChange, this);
      const headerId = `${this.areaId}-sort-search-header`;
      this.addEvent($I(headerId), "click", this.onSearchClick, this);
    }
  };

  this.messages = {
    refresh: refreshOnExternalEvent
  };

  this.init = function(params) {
    parentController = params.parentController;
    checkeds = [];
    templateParams = {
      areaId: params.areaId,
      parentController,
      checkable: params.checkable,
      checkeds() {
        return checkeds;
      },
      isCheckedAll() {
        return isCheckedAll;
      }
    };
    $area = $I(this.areaId);
    $viewport = $area.querySelector(".viewport");
    $sortSelect = $area.querySelector('[name="sortBy"]');
    $searchInput = $area.querySelector('[name="searchInput"]');
    if ($sortSelect) sortBy = $sortSelect[0].value;
    height = params.height;
    renderer = new TableRenderer(templateParams);
    renderer.render([], 0, 0, "", true);
    if (params.url) {
      onFetch = params.onFetch
        ? params.parentController[params.onFetch].bind(params.parentController)
        : null;
      tableModel = new FetchableTableModel({
        url: params.url,
        afterFetchCallback: self.renderNew.bind(self),
        errHandler: promise => {
          to(promise, self.app.wait.hide.bind(null, self.areaId));
        }
      });
      self.app.wait.show(self.areaId);
      tableModel.fetch(parentController.filters);
      fetchingMode = true;
    } else {
      validate.notNull(params.tableModelProperty);
      tableModel = new SimpleTableModel({
        model: utils.getPropertyOrNull(
          parentController,
          params.tableModelProperty
        )
      });
      this.renderNew();
    }
    this.events();
    if (params.registerAPI) {
      parentController[params.registerAPI]({
        getAllChecked: this.getAllChecked.bind(this)
      });
    }
  };

  function refreshOnExternalEvent(params) {
    if (!fetchingMode)
      throw new Error(
        "Nie można wysyłać sygnału odświeżenia do tabeli, która nie obsługuje docągania danych"
      );
    lastSearchString = null;
    if ($searchInput) $searchInput.value = "";
    self.app.wait.show(self.areaId);
    tableModel.fetch(parentController.filters);
  }

  function drawPaginator() {
    const { pageNumber, pageSize } = parentController.filters;
    const total = tableModel.getTotal();
    const container$ = $I(`${self.areaId}-paginator`);
    // debugger;
    if (tableModel.getCurrentNumberOfRows() < 1) {
      container$.innerHTML =
        '<div class="vx-list-paging">Brak danych do wyświetlenia</div>';
      return;
    }
    const totalPages = Math.ceil(total / pageSize);
    const message = `<p style="margin-top: 4px;">Wyświetlanie strony <b>${pageNumber}</b> z <b>${totalPages}</b></p>`;
    // wyswietlaj na raz maksymalnie 1 + pageOffset
    const pageOffset = 19;
    const minPage = pageNumber - pageOffset > 0 ? pageNumber - pageOffset : 1;
    const maxPage =
      pageNumber + pageOffset <= totalPages
        ? pageNumber + pageOffset
        : totalPages;
    const previousSectionPage = minPage - 1 > 0 ? minPage - 1 : 1;
    const nextSectionPage = maxPage + 1 <= totalPages ? maxPage + 1 : maxPage;
    let content = "";
    const firstPageButton = `<li class="icon ${
      pageNumber === 1 ? "grey" : ""
    }" pagenum="${1}">⏮</li>`;
    const lastPageButton = `<li class="icon ${
      pageNumber === totalPages ? "grey" : ""
    }" pagenum="${totalPages}">⏭</li>`;
    const previousSectionButton = `<li class="icon ${
      previousSectionPage === 1 ? "grey" : ""
    }" pagenum="${previousSectionPage}">⏪</li>`;
    const nextSectionButton = `<li class="icon ${
      nextSectionPage === totalPages ? "grey" : ""
    }" pagenum="${nextSectionPage}">⏩</li>`;

    for (let i = minPage; i <= maxPage; i++) {
      const el = `<li class="${
        i === pageNumber ? "act" : "inact"
      }" pagenum="${i}">${i}</li>`;
      content += el;
    }

    const innerHTML = `<div class="vx-list-paging">${message}<ul>${firstPageButton}${previousSectionButton}${content}${nextSectionButton}${lastPageButton}</ul></div>`;
    container$.innerHTML = innerHTML;
  }

  this.renderNew = function() {
    if (sortBy) {
      const sortingFunction = dynamicSort(sortBy);
      tableModel.sort(sortingFunction);
    }
    // this.render(0, 18);
    drawPaginator();
    renderer.render(tableModel.getModel(), 0, 18, lastSearchString, false);
    this.initScrollbarIfNeeded();
    if ($viewport && $viewport.scrollHeight > $viewport.clientHeight) {
      $area.classList.remove("hide-scroll");
      $area.classList.add("active-scroll");
    } else {
      $area.classList.remove("active-scroll");
      $area.classList.add("hide-scroll");
    }
    pending = false;
    if (onFetch) onFetch(tableModel.getModel());
    self.app.wait.hide(self.areaId);
  };

  const render = function(from, count) {
    renderer.render(
      tableModel.getModel(),
      from,
      count,
      lastSearchString,
      false
    );
  };

  this.initScrollbarIfNeeded = function() {
    if (!height) return;
    if (!scrollbarController)
      scrollbarController = new ScrollbarController({
        areaId: `${this.areaId}-scroll`,
        renderCallback: render,
        getCurrentNumberOfRows: this.getCurrentNumberOfRows
      });
    else scrollbarController.refresh();
  };

  this.getCurrentNumberOfRows = function() {
    return tableModel.getCurrentNumberOfRows();
  };

  this.onSortSelectChange = event => {
    const $select = event.target;
    sortBy = $select.value;
    this.renderNew();
  };

  this.onClickHandler = function(event) {
    // handle pagination
    const newPage = event.target.getAttribute("pagenum");

    if (newPage) {
      parentController.filters.pageNumber = +newPage;
      refreshOnExternalEvent();
      return;
    }

    // check rowaction
    let checkRow = event.target.getAttribute("vx-checked");
    if (checkRow !== null) {
      if (checkRow === "") {
        if (!event.target.checked) {
          checkeds = [];
          isCheckedAll = false;
          scrollbarController.refreshView();
        } else {
          checkeds = [];
          const rowsCount = tableModel.getCurrentNumberOfRows();
          for (let i = 0; i < rowsCount; i++) {
            checkeds.push(tableModel.getRowByIndex(i));
          }
          isCheckedAll = true;
          scrollbarController.refreshView();
        }
        return;
      }
      checkRow = parseInt(checkRow);
      if (!event.target.checked) {
        const currentRow = tableModel.getRowByIndex(checkRow);
        checkeds = checkeds.filter(function(e) {
          return e !== currentRow;
        });
        isCheckedAll = false;
        scrollbarController.refreshView();
      } else {
        checkeds.push(tableModel.getRowByIndex(checkRow));
      }
      return;
    }
    // parent action
    const parentControllerAction = event.target.getAttribute("vx-table-action");
    if (parentControllerAction == null) return;
    let $element = event.target;
    let rowNum;
    while ($element != $area) {
      rowNum = $element.getAttribute("vx-row-number");
      if (!rowNum) $element = $element.parentNode;
      else break;
    }
    if (!rowNum)
      throw new Error(
        "Nie znaleziono w tabeli elementu z atrybutem vx-row-number"
      );
    const row = tableModel.getRowByIndex(rowNum);
    parentController[parentControllerAction](row);
  };

  this.onSearchClick = function(event) {
    const searchAttribute = event.target.getAttribute("vx-search");
    if (!searchAttribute) return;
    this.search();
  };

  this.onSearchInputChange = function(event) {
    isCheckedAll = false;
    const enterPressed = event.keyCode == ENTER;
    if (!enterPressed && $searchInput.value) return;
    if (enterPressed) event.preventDefault();
    this.search();
  };

  this.search = function() {
    const { value } = $searchInput;
    if (value == lastSearchString) return;
    lastSearchString = value;
    tableModel.filter(lastSearchString);
    this.renderNew();
  };

  this.getAllChecked = function() {
    return checkeds;
  };
}

// funkcja, która sortuje tablicę obiektów po wskazanej własności, jeśli nazwa
// własności jest poprzedzona znakiem "-"
// odwracany jest porządek sortowania
var dynamicSort = function(property) {
  let sortOrder = 1;
  let numericSort = false;

  if (property[0] === "!") {
    sortOrder = -1;
    property = property.substr(1);
  } else if (property[0] === "+") {
    numericSort = true;
    property = property.substr(1);
  } else if (property[0] === "-") {
    sortOrder = -1;
    numericSort = true;
    property = property.substr(1);
  }

  return function(a, b) {
    let result;
    if (numericSort)
      result =
        a[property] - b[property] > 0
          ? 1
          : a[property] - b[property] < 0
          ? -1
          : 0;
    else result = a[property].localeCompare(b[property]);
    return result * sortOrder;
  };
};

const filterFunction = function(searchString) {
  return function(obj) {
    for (const key in obj) {
      if (obj[key] == null) continue;
      if (
        obj[key]
          .toString()
          .toUpperCase()
          .indexOf(searchString.toUpperCase()) > -1
      )
        return true;
    }
    return false;
  };
};

var SimpleTableModel = function(params) {
  validate.notNull(params.model, "Nie podano obiektu modelu");
  let { model } = params;
  const origModel = params.model;

  this.getModel = function() {
    return model;
  };

  this.getRowByIndex = function(rowIndex) {
    return model[rowIndex];
  };

  this.getCurrentNumberOfRows = function() {
    return model.length;
  };

  this.filter = function(searchString) {
    if (searchString == "") {
      model = origModel;
      return;
    }
    const filter = filterFunction(searchString);
    model = origModel.filter(filter);
  };

  this.sort = function(sortFunction) {
    model.sort(sortFunction);
  };
};

var FetchableTableModel = function(params) {
  let model;
  let origModel;
  let afterFetchCallback;
  let url;
  let total;
  let filteredModel;
  let errHandler;

  this.init = function(params) {
    validate.notNull(params.url, "Nie podano url do pobrania danych");
    url = params.url;
    afterFetchCallback = params.afterFetchCallback;
    errHandler = params.errHandler;
  };

  this.getModel = function() {
    return model;
  };

  this.getRowByIndex = function(rowIndex) {
    return model[rowIndex];
  };

  this.getCurrentNumberOfRows = function() {
    return model.length;
  };

  this.getTotal = function() {
    return total;
  };

  const buildUrlParams = function(url, queryParams) {
    const queryParamsArray = [];
    queryParams || (queryParams = {});
    Object.entries(queryParams).forEach(([key, value]) => {
      if (key != "url" && value)
        queryParamsArray.push(`${key}=${encodeURIComponent(value)}`);
    });
    const params = queryParamsArray.join("&");
    const separator = url.indexOf("?") > -1 ? "&" : "?";
    return url + separator + params;
  };

  this.fetch = function(params) {
    if (params && params.url) url = params.url;
    const u = url;
    const newUrl = params ? buildUrlParams(u, params) : u;
    errHandler(ajax.get(newUrl).then(this.afterFetch));
  };

  this.filter = function(searchString) {
    if (searchString == "") {
      model = origModel;
      return;
    }
    const filter = filterFunction(searchString);
    model = origModel.filter(filter);
  };

  this.sort = function(sortFunction) {
    model.sort(sortFunction);
  };

  this.afterFetch = function(data) {
    total = data.maxResults;
    model = data.list;
    origModel = data.list;
    afterFetchCallback();
  };

  this.init(params);
};

var TableRenderer = function(params) {
  const { areaId } = params;
  const headerId = `${areaId}-header`;
  const bodyId = `${areaId}-body`;

  const $table = $I(areaId);
  const $pager = $table.getElementsByClassName("pagination")[0];
  const $body = $I(bodyId);
  let rowTemplate = Array.from($table.querySelectorAll(".table-row"))
    .map(el => el.outerHTML)
    .join("");
  const headerTemplate = $I(headerId)?.outerHTML;

  $body.innerHTML = "";
  $body.style.display = "";
  rowTemplate = rowTemplate.replace(/{{/g, "{%");
  rowTemplate = rowTemplate.replace(/}}/g, "%}");
  rowTemplate = `{% var len = o.length; for (var rowIndex=o.from ; rowIndex<o.to; rowIndex++){ var row = o.items[rowIndex]; var controller = o.parentController; %}${rowTemplate}{% } %}`;
  rowTemplate = utils.decode(rowTemplate);
  rowTemplate = headerTemplate + rowTemplate;

  this.render = function(items, from, count, lastSearchString, pending) {
    // $I(headerId).innerHTML = vx.tmpl(headerTemplate, {
    //	parentController: params.parentController,
    //	checkable: params.checkable,
    //		isCheckedAll: params.isCheckedAll()
    // });
    if (pending) {
      this.drawPendingMessage();
      return;
    }

    if (!items || items.length == 0) {
      this.drawEmptyMessage();
      return;
    }

    const len = items.length;
    if (count > len - from) count = len - from;
    const to = from + count;
    const o = {
      items,
      from,
      to,
      parentController: params.parentController,
      checkable: params.checkable,
      checkeds: params.checkeds()
    };
    let rowsHtml = vx.generateTemplate(rowTemplate, o);
    if (lastSearchString)
      rowsHtml = rowsHtml.replace(
        new RegExp(`${lastSearchString}(?!([^<]+)?>)`, "gi"),
        '<b style="background-color:#ff0;font-size:100%">$&</b>'
      );
    const $tableBody = $I(bodyId);
    $tableBody.innerHTML = rowsHtml;
  };

  this.drawPendingMessage = function() {
    let rowsHtml = "";
    const $tableBody = $I(bodyId);
    let preloaderHtml =
      '<div class="data-table-item" style="border:none;"><span class="data-table-cell " style="width: 100%; height: 100px"><div class="preloader show"><div class="spinner">';
    for (let i = 1; i <= 12; ++i) {
      preloaderHtml += `<div class="dot${i} spinner-dot"></div>`;
    }
    preloaderHtml += "</div></div></div></div>";
    rowsHtml += preloaderHtml;
    $tableBody.innerHTML = `<div class="data-table-item vo-empty-table-message">${rowsHtml}<div>`;
  };

  this.drawEmptyMessage = function() {
    const $tableBody = $I(bodyId);
    $tableBody.innerHTML =
      '<div class="data-table-item vo-empty-table-message"></div>';
  };
};

var ScrollbarController = function(params) {
  vx.extendController(this);
  let currentPosition = 0;
  let currentRowNumber = 0;
  let scrollableHeight = 0;
  let getCurrentNumberOfRows; // liczba wierszy w tabeli- sluzy do pozycjonowania wskaznika scrollowania w pionie
  this.$overview = null; // div, w ktorym znajdzie sie scrollowana tresc
  this.$thumb = null; // przesuwajacy sie po prawej stronie pasek pokazujacy gdzie jestesmy
  this.$scrollbar = null; // div otaczajacy scrollbar
  let thumbHeight = 50; // wysokość thumba
  let thumbTop; // odleglosc thumba od gory
  // var delayedFunctions = new vx.DealyedFunctions(1);   //rzeczywiste przerysowywanie zostanie opoznione, krecenie kolkiem generuje wiele zdarzen scroll
  // chodzi o to by przerysowac, gdy uzytkownik faktycznie przestanie krecic
  let renderCallback; // funkcja kontrolera nadrzednego odpowiedzialna za przerenderowanie podczas krecenia/przesuwania scrolla
  const WHEEL_SPEED = typeof InstallTrigger !== "undefined" ? 0.33 : 0.009; // AFAIK- dostosowanie do IE
  const ROWS_TO_RENDER = 18; // ile wierszy należy wyrenderować
  let rowsOnViewport; // ile

  this.events = function() {
    this.addEvent($I(this.wrapperId), "wheel", this.onScroll, this); // for moder webbrowser
    this.addEvent($I(this.wrapperId), "mousewheel", this.onScroll, this);
    this.addEvent($I(this.wrapperId), "DOMMouseScroll", this.onScroll, this); // for old firefox
  };

  this.init = function(params) {
    validate.notNull(params.areaId, "Nalezy podac obszar dla scrolla");
    validate.notNull(
      params.renderCallback,
      "Nalezy podac funkcje odpowiedzialna za renderowanie podczas scrollowania"
    );
    validate.notNull(
      params.getCurrentNumberOfRows,
      "Nalezy podac funkcje odpowiedzialna za zwrocenie aktualnej liczby wierszy w tabeli"
    );
    this.areaId = params.areaId;
    renderCallback = params.renderCallback;
    getCurrentNumberOfRows = params.getCurrentNumberOfRows;
    this.wrapperId = `${this.areaId}-wrapper`;
    scrollableHeight = parseInt($I(this.wrapperId).style.height);
    this.$overview = $I(this.areaId);
    this.$thumb = $I(`${this.areaId}-thumb`);
    this.$scrollbar = $I(`${this.areaId}-scrollbar`);
    this.events();
    this.showOrHideScroll();
    new DragController({
      container: this.$scrollbar,
      movingElement: this.$thumb,
      onMoveCallback: this.scrollMoveCallback.bind(this),
      onClickCallback: this.scrollClickCallback.bind(this),
      parent: this
    });
    this.calculateThumbHeight();
  };

  this.refresh = function() {
    currentPosition = 0;
    currentRowNumber = 0;
    this.showOrHideScroll();
    this.calculateThumbHeight();
    this.adjustThumbOnScroll();
  };

  this.calculateThumbHeight = function() {
    const rowsNumber = getCurrentNumberOfRows();
    const predictedRowHeight = 50;
    // predictedRowHeight to statystyczna zakladana wysokosc wiersza- na potrzeby obliczania wysokosci thumba w zupelnosci wystarczy
    let scaleFactor = scrollableHeight / (predictedRowHeight * rowsNumber);
    if (scaleFactor > 1) {
      scaleFactor = scrollableHeight / parseInt(this.$overview.clientHeight);
    }
    thumbHeight = scrollableHeight * scaleFactor;
    if (thumbHeight < 30) thumbHeight = 30;
    this.$thumb.style.height = `${thumbHeight}px`;
  };

  this.onScroll = function(e) {
    e.stopPropagation();
    e.preventDefault();
    const speed = e.deltaY || e.detail || (-1 / 3) * e.wheelDelta;
    let delta = speed * WHEEL_SPEED;
    // jesli zblizamy sie do konca scrolla to ograniczamy predkosc
    const rowsNumber = getCurrentNumberOfRows();
    if (currentPosition > rowsNumber - ROWS_TO_RENDER && delta > 1) {
      delta = 1;
    }
    const contentHeight = parseInt(this.$overview.clientHeight);
    // jesli cale okienko jest widoczne nie musimy zmieniac juz pozycji chyba, ze uzytkownik scrolluje w gore
    if (contentHeight > scrollableHeight || speed < 0) {
      currentPosition += delta;
    }
    this.refreshView();
    this.adjustThumbOnScroll();
  };

  this.refreshView = function() {
    if (currentPosition < 0) currentPosition = 0;
    const numberOfRows = getCurrentNumberOfRows();
    if (parseInt(currentPosition) > numberOfRows) {
      currentPosition = numberOfRows;
    }
    currentRowNumber = parseInt(currentPosition);
    renderCallback(currentRowNumber, ROWS_TO_RENDER);
    // this.adjustThumb();
  };

  this.adjustThumbOnScroll = function() {
    const rowsNumber = getCurrentNumberOfRows();
    const contentHeight = parseInt(this.$overview.clientHeight);
    let renderedRows = ROWS_TO_RENDER;
    if (currentRowNumber > rowsNumber - ROWS_TO_RENDER)
      renderedRows = rowsNumber - currentRowNumber;
    const statisticalRowHeight = parseInt(contentHeight / renderedRows);
    rowsOnViewport = parseInt(scrollableHeight / statisticalRowHeight); // ile wierszy jest widocznych na ekranie (45 to minimalna wysokość wiersza)
    // POZYCJA OD GORY
    // wolny obszar, w ramach ktorego nastepuje scrollowanie
    const spaceHeight = scrollableHeight - thumbHeight;
    // procent aktualnego przesuniecia
    const currentShift = currentRowNumber / (rowsNumber - rowsOnViewport);
    // wymagane przesuniecie od gory
    thumbTop = currentShift * spaceHeight;
    this.$thumb.style.top = `${thumbTop}px`;
  };

  this.adjustThumbOnClickOrMove = function(top) {
    // POZYCJA OD GORY
    // wolny obszar, w ramach ktorego nastepuje scrollowanie
    const spaceHeight = scrollableHeight - thumbHeight;
    if (top > spaceHeight) top = spaceHeight;
    if (top < 0) top = 0;
    // procent aktualnego przesuniecia
    // wymagane przesuniecie od gory
    thumbTop = top;
    this.$thumb.style.top = `${thumbTop}px`;
  };

  this.scrollMoveCallback = function(params) {
    const relativeTop = params.top;
    const heightPercent = relativeTop / (scrollableHeight - thumbHeight);
    const rowsNumber = getCurrentNumberOfRows();
    currentPosition = rowsNumber * heightPercent;
    this.refreshView();
    this.adjustNumberOfVisibleRows();
    this.adjustThumbOnClickOrMove(relativeTop);
  };

  this.scrollClickCallback = function(params) {
    const relativeTop = params.top;
    const heightPercent = relativeTop / scrollableHeight;
    const rowsNumber = getCurrentNumberOfRows();
    currentPosition = rowsNumber * heightPercent;
    this.refreshView();
    this.adjustNumberOfVisibleRows();
    this.adjustThumbOnClickOrMove(relativeTop);
  };

  // jesli z kalkulacji wyjdzie, ze na ekranie widzimy mniej niz 18 wierszy
  // to moze sie okazac, ze dół tabeli jest pusty
  this.adjustNumberOfVisibleRows = function() {
    var contentHeight = parseInt(this.$overview.clientHeight);
    if (contentHeight <= scrollableHeight) {
      currentPosition -= 1;
      this.refreshView();
      this.adjustNumberOfVisibleRows();
      var contentHeight = parseInt(this.$overview.clientHeight);
      if (contentHeight > scrollableHeight) {
        this.goOneRowForward();
      }
    }
  };

  this.goOneRowForward = function() {
    currentPosition += 1;
    this.refreshView();
  };

  this.isScrollingNeccesary = function() {
    const contentHeight = parseInt(this.$overview.clientHeight);
    const maxTop = scrollableHeight - contentHeight;
    if (maxTop >= 0) return false;
    return true;
  };

  this.showOrHideScroll = function() {
    if (!this.isScrollingNeccesary()) this.$scrollbar.style.display = "none";
    else this.$scrollbar.style.display = "block";
  };

  this.init(params);
};

var DragController = function(params) {
  let moveFunction;
  let mouseUpFunction;
  let leaveFunction;
  let $container;
  let $movingElement;
  let offset = 0; // przesuniecie pomiedzy gorą thumba o miejscem, w którym go kliknięto
  let containerPosition;
  const containerSize = {};
  let onMoveCallback;
  let onMouseUpCallback;
  let onAreaClickCallback;
  let lastCursorPosition;

  this.events = function() {
    params.parent.addEvent($movingElement, "mousedown", this.onMouseDown);
    params.parent.addEvent($container, "click", this.onAreaClick);
  };

  this.init = function(params) {
    validate.notNull(
      params.container,
      "Nalezy wskazac kontener wewnatrz, ktorego nastepuje przesuwanie elementu"
    );
    validate.notNull(params.movingElement, "Nalezy wskazac przesuwany element");
    validate.notNull(params.onMoveCallback, "Nalezy wskazac onMoveCallback");
    validate.notNull(params.onClickCallback, "Nalezy wskazac onClickCallback");
    $container = params.container;
    $movingElement = params.movingElement;
    containerPosition = dom.findPos($container);
    containerSize.height = $container.offsetHeight;
    onMoveCallback = params.onMoveCallback;
    onAreaClickCallback = params.onClickCallback;
    this.events();
  };

  this.onAreaClick = event => {
    const $el = event.target;
    // kliknięcie w przesuwalny element nie skutkuje niczym
    if ($el === $movingElement || dom.isChildOf($el, $movingElement)) return;
    // kliknięcie w obszar poza przesuwalnym elementem
    const relativePosition = this.calculateRelativeCursorPosition(event);

    event.stopPropagation();
    onAreaClickCallback(relativePosition);
  };

  this.onMouseDown = event => {
    // to sprawia, ze przesuwanie kursorem nic nie zaznacza w dokumencie
    event.preventDefault();
    event.stopPropagation();
    lastCursorPosition = this.calculateRelativeCursorPosition(event);
    const top = parseInt($movingElement.style.top);
    offset = lastCursorPosition.top - top;
    // nakladamy zdarzenia na caly dokument, dzieki czemu nawet "wyjechanie" poza obszar nadal przesuwa suwaczek
    moveFunction = params.parent.addEvent(
      document,
      "mousemove",
      this.onMouseMove,
      this
    );
    mouseUpFunction = params.parent.addEvent(
      document,
      "mouseup",
      this.onMouseUp,
      this
    );
    leaveFunction = params.parent.addEvent(
      document,
      "mouseout",
      this.onLeave,
      this
    );
  };

  this.onMouseMove = function(e) {
    if (e.buttons & 1 || (e.buttons === undefined && e.which == 1)) {
      const relativePosition = this.calculateRelativeCursorPosition(e);
      e.stopPropagation();
      relativePosition.top -= offset;
      onMoveCallback(relativePosition);
    } else {
      this.unbindAll();
    }
  };

  this.onLeave = function(event) {
    // sprzatanie nasluchow z document
    if (event.target.tagName != "HTML") return;
    this.unbindAll();
  };

  this.onMouseUp = function(event) {
    this.unbindAll();
  };

  this.unbindAll = function unbindAll(event) {
    // sprzatanie nasluchow z document
    params.parent.removeEvent(document, "mousemove", moveFunction);
    params.parent.removeEvent(document, "mouseup", mouseUpFunction);
    params.parent.removeEvent(document, "mouseout", leaveFunction);
  };

  // pozycja kursora myszy względem kontenera, w ktorym chcemy przesuwać
  this.calculateRelativeCursorPosition = function(event) {
    const rect = $container.getBoundingClientRect();
    const zoom = this.getZoom();
    let top = event.clientY / zoom - rect.top;
    if (top < 0) top = 0;
    const scaledHeight = rect.height / zoom;
    if (top > scaledHeight) top = scaledHeight;
    return { top };
  };

  this.getZoom = function() {
    return parseFloat(document.querySelector(".main-wrapper").style.zoom) || 1;
  };

  this.init(params);
};
