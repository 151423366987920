/* eslint-disable */

/*
 * Możliwe schematy środowisk:
 * abc.xyz/{moduł}
 * abc.xyz/api/{moduł}
 * abc.xyz/{kontekst}/{moduł}
 * abc.xyz/{kontekst}/api/{moduł}
 */
const pathArr = window.location.pathname.split("/");

export const currentModule = pathArr[pathArr.length - 1 - 1]; // pathArr[lastIndex - ending "/"]
export const currentEnvironment =
  window.location.origin + pathArr.slice(0, -2).join("/") + "/";
export let currentLocation = currentModule + "/" + window.location.hash;

function onHashChangeHandler() {
  currentLocation = currentModule + "/" + window.location.hash;
}

window.addEventListener("hashchange", onHashChangeHandler, false);

// sprawdzanie modułu i widoku

export function isCurrentLocation(location) {
  return location === currentLocation;
}

export function isCurrentModule(module) {
  return module === currentModule;
}

export function getAbsoluteUrl(url) {
  return currentEnvironment + url;
}
